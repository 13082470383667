import { configs } from "../config";
import { commonFunctions } from "../_utilities/commonFunctions";
import { configes } from "../webData";

export const configService = {
  getConfigByTenantId,
  getSeoData,
};

function getConfigByTenantId(query = "?section=socials&section=general") {
  const requestOptions = commonFunctions.getRequestOptions("GET");
  console.log("config service", requestOptions);
  return fetch(
    `${configs.localApiUrl}/Configurations/${configes.domainId}${query}`,
    requestOptions
  ).then((response) => response.json());
}

function getSeoData(query = "?section=home&section=carlisting&section=contact") {
  const requestOptions = commonFunctions.getRequestOptions("GET");
  return fetch(
    `${configs.localApiUrl}/getSeo/${configes.domainId}${query}`,
    requestOptions
  ).then((response) => response.json());
}
