import React, { useState, useEffect } from "react";
import location_icon from "../../ulits/images/location_icon.svg";
import dateicon from "../../ulits/images/date-icon.svg";
import CustomLoader from "../../components/CustomeLoader";
import { useDispatch, useSelector } from "react-redux";
import { blogAction } from "../../_actions/blogDetail.action";
import { status } from "../../_constants/commonLhd";
import { Link, useNavigate } from "react-router";
import { configs } from "../../config";

function BlogListing() {
  const [loader, setLoading] = useState(true);
  const [blog, setBlogs] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const blogData = useSelector((state) => state.blog);
  console.log("24 blogs", blogData);
  console.log("blogs", blog);

  // console.log("24 blogs", blogData.blogDetail_data);
  useEffect(() => {
    if (blogData?.blog_status === status.SUCCESS) {
      setBlogs(blogData.blog_data);
    }
  }, [blogData?.blog_data]);

  useEffect(() => {
    dispatch(blogAction.getBlogDetail());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 600);
  }, []);

  return (
    <>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="blog-listing-section">
            <div className="container">
              <div className="blog-listing-title text-center">
                <h1>BLOG PAGE</h1>
              </div>
              <div className="row">
                {blog &&
                  blog.length > 0 &&
                  blog.map((blog, index) => (
                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-12">
                      <Link to="/blog-details">
                        <div className="blog-listing-box">
                          <div className="blog-listing-img">
                            <img
                              src={`${configs.localApiUrl}${blog?.image}`}
                              onClick={() =>
                                navigate(`/blog-details`, { state: blog })
                              }
                            />
                          </div>

                          <div className="blog-listing-text" key={index}>
                            <h1>{blog.sortDescription}</h1>
                            <p>{blog.description}</p>
                            <div className="meta-middle-unit">
                              <div className="blog-listing-date">
                                <img src={dateicon} alt="" />
                                <span>{blog.createdAt}</span>
                              </div>
                              <div className="blog-listing-date">
                                <img src={location_icon} alt="" />
                                <span>UK</span>
                              </div>
                            </div>
                            <Link to="#" className="btn btn-primary">
                              READ MORE
                            </Link>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}

                <button className="primary-btn-list" title="LOAD MORE">
                  LOAD MORE
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BlogListing;
