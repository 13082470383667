import React, { useState, useEffect } from "react";
import CustomLoader from "../../components/CustomeLoader";
import googlepay from "../../ulits/images/google-pay.svg";
import staricon from "../../ulits/images/star-img.svg";

function AboutTestimonials() {
  const [loader, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 600);
  }, []);
  return (
    <>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="google-review-section about-google-review">
            <div className="container">
              <div className="heading-title">
                <h1>GOOGLE REVIEWS</h1>
              </div>
              <div className="google-review-wrapper-inner first-box">
                <div className="google-content-inner">
                  <img
                    src={googlepay}
                    alt="google-img"
                    className="google-img"
                  />
                  <p>5 stars</p>
                  <img src={staricon} alt="arrow-icon" className="star-icon" />
                  <p>234 reviews</p>
                </div>
              </div>
              <div className="google-review-wrapper-box">
                <div className="google-review-wrapper">
                  <div className="google-review-box">
                    <div className="google-review-img">
                      <img
                        src={staricon}
                        alt="arrow-icon"
                        className="google-img"
                      />
                      <img
                        src={googlepay}
                        alt="google-img"
                        className="star-icon"
                      />
                    </div>
                    <div className="google-review-content">
                      <h2>1 month ago</h2>
                      <p>
                        Lorem ipsum odor amet, consectetuer adipiscing elit.
                        Justo blandit sit nunc; curae enim lectus! Elit mi
                        auctor duis ligula magnis. Congue lectus rhoncus odio
                        venenatis sem. Quam sollicitudin potenti turpis torquent
                        semper.
                      </p>
                      <h2>John Smith</h2>
                    </div>
                  </div>
                  <div className="google-review-box">
                    <div className="google-review-img">
                      <img
                        src={staricon}
                        alt="arrow-icon"
                        className="google-img"
                      />
                      <img
                        src={googlepay}
                        alt="google-img"
                        className="star-icon"
                      />
                    </div>
                    <div className="google-review-content">
                      <h2>1 month ago</h2>
                      <p>
                        Lorem ipsum odor amet, consectetuer adipiscing elit.
                        Justo blandit sit nunc; curae enim lectus! Elit mi
                        auctor duis ligula magnis. Congue lectus rhoncus odio
                        venenatis sem. Quam sollicitudin potenti turpis torquent
                        semper.
                      </p>
                      <h2>John Smith</h2>
                    </div>
                  </div>
                  <div className="google-review-box">
                    <div className="google-review-img">
                      <img
                        src={staricon}
                        alt="arrow-icon"
                        className="google-img"
                      />
                      <img
                        src={googlepay}
                        alt="google-img"
                        className="star-icon"
                      />
                    </div>
                    <div className="google-review-content">
                      <h2>1 month ago</h2>
                      <p>
                        Lorem ipsum odor amet, consectetuer adipiscing elit.
                        Justo blandit sit nunc; curae enim lectus! Elit mi
                        auctor duis ligula magnis. Congue lectus rhoncus odio
                        venenatis sem. Quam sollicitudin potenti turpis torquent
                        semper.
                      </p>
                      <h2>John Smith</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="google-review-wrapper-box">
                <div className="google-review-wrapper">
                  <div className="google-review-box">
                    <div className="google-review-img">
                      <img
                        src={staricon}
                        alt="arrow-icon"
                        className="google-img"
                      />
                      <img
                        src={googlepay}
                        alt="google-img"
                        className="star-icon"
                      />
                    </div>
                    <div className="google-review-content">
                      <h2>1 month ago</h2>
                      <p>
                        Lorem ipsum odor amet, consectetuer adipiscing elit.
                        Justo blandit sit nunc; curae enim lectus! Elit mi
                        auctor duis ligula magnis. Congue lectus rhoncus odio
                        venenatis sem. Quam sollicitudin potenti turpis torquent
                        semper.
                      </p>
                      <h2>John Smith</h2>
                    </div>
                  </div>
                  <div className="google-review-box">
                    <div className="google-review-img">
                      <img
                        src={staricon}
                        alt="arrow-icon"
                        className="google-img"
                      />
                      <img
                        src={googlepay}
                        alt="google-img"
                        className="star-icon"
                      />
                    </div>
                    <div className="google-review-content">
                      <h2>1 month ago</h2>
                      <p>
                        Lorem ipsum odor amet, consectetuer adipiscing elit.
                        Justo blandit sit nunc; curae enim lectus! Elit mi
                        auctor duis ligula magnis. Congue lectus rhoncus odio
                        venenatis sem. Quam sollicitudin potenti turpis torquent
                        semper.
                      </p>
                      <h2>John Smith</h2>
                    </div>
                  </div>
                  <div className="google-review-box">
                    <div className="google-review-img">
                      <img
                        src={staricon}
                        alt="arrow-icon"
                        className="google-img"
                      />
                      <img
                        src={googlepay}
                        alt="google-img"
                        className="star-icon"
                      />
                    </div>
                    <div className="google-review-content">
                      <h2>1 month ago</h2>
                      <p>
                        Lorem ipsum odor amet, consectetuer adipiscing elit.
                        Justo blandit sit nunc; curae enim lectus! Elit mi
                        auctor duis ligula magnis. Congue lectus rhoncus odio
                        venenatis sem. Quam sollicitudin potenti turpis torquent
                        semper.
                      </p>
                      <h2>John Smith</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="google-review-wrapper-box">
                <div className="google-review-wrapper">
                  <div className="google-review-box">
                    <div className="google-review-img">
                      <img
                        src={staricon}
                        alt="arrow-icon"
                        className="google-img"
                      />
                      <img
                        src={googlepay}
                        alt="google-img"
                        className="star-icon"
                      />
                    </div>
                    <div className="google-review-content">
                      <h2>1 month ago</h2>
                      <p>
                        Lorem ipsum odor amet, consectetuer adipiscing elit.
                        Justo blandit sit nunc; curae enim lectus! Elit mi
                        auctor duis ligula magnis. Congue lectus rhoncus odio
                        venenatis sem. Quam sollicitudin potenti turpis torquent
                        semper.
                      </p>
                      <h2>John Smith</h2>
                    </div>
                  </div>
                  <div className="google-review-box">
                    <div className="google-review-img">
                      <img
                        src={staricon}
                        alt="arrow-icon"
                        className="google-img"
                      />
                      <img
                        src={googlepay}
                        alt="google-img"
                        className="star-icon"
                      />
                    </div>
                    <div className="google-review-content">
                      <h2>1 month ago</h2>
                      <p>
                        Lorem ipsum odor amet, consectetuer adipiscing elit.
                        Justo blandit sit nunc; curae enim lectus! Elit mi
                        auctor duis ligula magnis. Congue lectus rhoncus odio
                        venenatis sem. Quam sollicitudin potenti turpis torquent
                        semper.
                      </p>
                      <h2>John Smith</h2>
                    </div>
                  </div>
                  <div className="google-review-box">
                    <div className="google-review-img">
                      <img
                        src={staricon}
                        alt="arrow-icon"
                        className="google-img"
                      />
                      <img
                        src={googlepay}
                        alt="google-img"
                        className="star-icon"
                      />
                    </div>
                    <div className="google-review-content">
                      <h2>1 month ago</h2>
                      <p>
                        Lorem ipsum odor amet, consectetuer adipiscing elit.
                        Justo blandit sit nunc; curae enim lectus! Elit mi
                        auctor duis ligula magnis. Congue lectus rhoncus odio
                        venenatis sem. Quam sollicitudin potenti turpis torquent
                        semper.
                      </p>
                      <h2>John Smith</h2>
                    </div>
                  </div>
                </div>
              </div>
              <button className="primary-btn-list" title="MORE GOOGLE REVIEWS">
                MORE GOOGLE REVIEWS
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AboutTestimonials;
