import React, { useState, useEffect } from "react";
import heart from "../ulits/images/heart.svg";
import { Link } from "react-router-dom";
import { configs } from "../config";
import location from "../ulits/images/location-icon.svg";

function Footer(props) {
  const [config, setConfig] = useState({});

  useEffect(() => {
    setConfig(props?.configData);
  }, [props?.configData]);

  const year = new Date().getFullYear();

  return (
    <div className="site-footer">
      <div className="footer-top">
        {config && Object.keys(config).length > 0 && (
          <div className="container">
            <div className="footer-logo text-center">
              <Link to="/">
                <img
                  src={
                    config &&
                    config.Footer_logo &&
                    `${configs.localApiUrl}${config?.Footer_logo}`
                  }
                  alt="footer-logo"
                  style={{ width: "15%" }}
                />
              </Link>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-2 col-md-6 col-sm-6 col-xs-6 col-12">
                <ul className="list-unstyled contents">
                  <li>
                    <Link>
                      <img
                        src={location}
                        // src={
                        //   config &&
                        //   config?.locationIcon &&
                        //   `${configs.localApiUrl}${config?.locationIcon}`
                        // }
                        alt="location-icon"
                      />
                      {config && config?.Address_line_1}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 col-xs-6 col-12">
                <ul className="list-unstyled contents">
                  <li>
                    <Link>
                      <img src={location} alt="location-icon" />
                      {/* <img
                        src={
                          config &&
                          config?.locationIcon &&
                          `${configs.localApiUrl}${config?.locationIcon}`
                        } */}
                      {/* alt="location-icon" */}
                      {/* /> */}
                      {config && config?.Address_line_2}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 col-xs-6 col-12">
                <ul className="list-unstyled contents">
                  <li>
                    <Link>
                      <img src={location} alt="location-icon" />
                      {/* {/* <img
                        src={
                          config &&
                          config?.locationIcon &&
                          `${configs.localApiUrl}${config?.locationIcon}`
                        }
                        alt="location-icon"
                      /> */}
                      {config && config?.Address_line_3}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 col-xs-6 col-12 d-none d-md-block">
                <ul className="list-unstyled contents">
                  <li>
                    <Link>
                      <img src={location} alt="location-icon" />
                      {/* {/* <img
                        src={
                          config &&
                          config?.locationIcon &&
                          `${configs.localApiUrl}${config?.locationIcon}`
                        }
                        alt="location-icon"
                      /> */}
                      {config && config?.Address_line_4}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-12">
                <ul className="list-unstyled">
                  <li>
                    <Link to={`tel:${config && config.Free_phone}`}>
                      FREE PHONE : {config && config.Free_phone}
                    </Link>
                  </li>
                  <li>
                    <Link to={`tel:${config && config.Landline}`}>
                      LANDLINE : {config && config.Landline}
                    </Link>
                  </li>
                  <li>
                    <Link to={`tel:${config && config.Contact_Number}`}>
                      MOBILE : {config && config.Contact_Number}
                    </Link>
                  </li>
                  <li>
                    <Link to={`mailto:${config && config.Support_Email}`}>
                      EMAIL : {config && config.Support_Email}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <ul className="list-inline">
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/terms-conditions">Terms of use</Link>
                </li>
                <li>
                  <Link to="/privacy">Privacy</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
              <p>
                &copy; {year} Copyright The Left Hand Drive Car Showroom
                Limited. All Rights Reserved.
              </p>
              <div className="loyalty-text">
                made with
                <em>
                  <img src={heart} alt="heart" />
                </em>
                by
                <Link to="https://softorigin.co.uk/" target="_blank">
                  {" "}
                  Soft Origin
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
