import React, { useState, useEffect } from "react";
import CustomLoader from "../../components/CustomeLoader";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, FreeMode } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { homeAction, pageAction } from "../../_actions";
import { status } from "../../_constants/commonLhd";
import { configs } from "../../config";
import { Helmet } from "react-helmet";

function AboutChooseus() {
  const [loader, setLoading] = useState(true);
  const [aboutData, setAboutData] = useState();
  const [brands, setBrands] = useState([]);
  const dispatch = useDispatch();

  const aboutChooseData = useSelector((state) => state.page);

  useEffect(() => {
    if (aboutChooseData && aboutChooseData.about_status === status.SUCCESS)
      setAboutData(aboutChooseData.about_data);
  }, [aboutChooseData.about_data]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 600);
  }, []);

  const homeData = useSelector((state) => state.home);

  useEffect(() => {
    dispatch(homeAction.getBrandsByTenantId());
    dispatch(pageAction.getAboutChooseData());
  }, []);

  useEffect(() => {
    if (homeData && homeData.brands_status === status.SUCCESS) {
      setBrands(homeData.brands_data);
    }
  }, [homeData.brands_data]);
  const isDesktop = window.innerWidth > 768;
  const gap = isDesktop ? 0.0285 * window.innerWidth : 15;

  return (
    <>
     <Helmet>
        <meta name="description" content={aboutData && aboutData.meta_description} />
        <meta name="keywords" content={aboutData && aboutData.meta_keywords} />
      </Helmet>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="about-list-section second choose-us">
            <div className="container">
              <div className="about-item-box">
                <div className="about-list-box">
                  <h1>{aboutData && aboutData.Title}</h1>
                  {parse(aboutData && aboutData.Description)}
                </div>
                <div className="about-list-img">
                  <img
                    src={`${configs.localApiUrl}${
                      aboutData && aboutData.Image
                    }`}
                    alt="about-col-2"
                  />
                </div>
              </div>
            </div>
            <div className="home-choose-us-section about-choose">
              <div className="container">
                <div className="about-list-choose">
                  <div className="choose-us-wrapper">
                    <div className="choose-us-box">
                      <div className="choose-us-icon d-block d-md-none">
                        <img
                          src={`${configs.localApiUrl}${
                            aboutData && aboutData.Blog_image_1
                          }`}
                          alt="tab-icon"
                        />
                      </div>
                      <div className="choose-us-content">
                        <img
                          src={`${configs.localApiUrl}${
                            aboutData && aboutData.Logo_1
                          }`}
                          alt="tab-icon"
                        />
                        {aboutData && aboutData.Blog_1
                          ? parse(aboutData.Blog_1)
                          : null}
                        {/* {parse(aboutData && aboutData.Blog_1)} */}
                      </div>
                    </div>
                    <div className="choose-us-box">
                      <div className="choose-us-icon d-block d-md-none">
                        <img
                          src={`${configs.localApiUrl}${
                            aboutData && aboutData.Blog_image_2
                          }`}
                          alt="tab-icon"
                        />
                      </div>
                      <div className="choose-us-content">
                        <img
                          src={`${configs.localApiUrl}${
                            aboutData && aboutData.Logo_2
                          }`}
                          alt="tab-icon"
                        />
                        {aboutData && aboutData.Blog_2
                          ? parse(aboutData.Blog_2)
                          : null}
                        {/* {parse(aboutData && aboutData.Blog2)} */}
                      </div>
                    </div>
                  </div>
                  <div className="choose-us-wrapper">
                    <div className="choose-us-box">
                      <div className="choose-us-icon d-block d-md-none">
                        <img
                          src={`${configs.localApiUrl}${
                            aboutData && aboutData.Blog_image_3
                          }`}
                          alt="tab-icon"
                        />
                      </div>
                      <div className="choose-us-content">
                        <img
                          src={`${configs.localApiUrl}${
                            aboutData && aboutData.Logo_3
                          }`}
                          alt="tab-icon"
                        />
                        {aboutData && aboutData.Blog_3
                          ? parse(aboutData.Blog_3)
                          : null}
                        {/* {parse(aboutData && aboutData.Blog3)} */}
                      </div>
                    </div>
                    <div className="choose-us-box">
                      <div className="choose-us-icon d-block d-md-none">
                        <img
                          src={`${configs.localApiUrl}${
                            aboutData && aboutData.Blog_image_4
                          }`}
                          alt="tab-icon"
                        />
                      </div>
                      <div className="choose-us-content">
                        <img
                          src={`${configs.localApiUrl}${
                            aboutData && aboutData.Logo_4
                          }`}
                          alt="tab-icon"
                        />
                        {aboutData && aboutData.Blog_4
                          ? parse(aboutData.Blog_4)
                          : null}
                        {/* {parse(aboutData && aboutData.Blog4)} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="brands-stock text-center">
            <div className="head-title">
              <h2>BRANDS WE WORK WITH</h2>
              <div className="u-wrapper d-none d-md-block">
                <div className="c-carousel">
                  <Swiper
                    modules={[Autoplay, FreeMode]}
                    spaceBetween={10}
                    speed={2000}
                    direction="horizontal"
                    autoplay={{
                      delay: 0,
                      disableOnInteraction: false,
                    }}
                    loop={true}
                    slidesPerView={7.5}
                    freeMode={true}
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                        speed: 5000,
                      },
                      768: {
                        slidesPerView: 1,
                      },
                      1024: {
                        slidesPerView: 7.5,
                      },
                    }}
                  >
                    {brands.map((brands) => (
                      <SwiperSlide>
                        <img
                          src={`${configs.localApiUrl}${brands.brandImage}`}
                          alt="Slide 1"
                          key={brands.id}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
              <div className="u-wrapper d-none d-md-block">
                <div className="c-carousel">
                  <Swiper
                    modules={[Autoplay, FreeMode]}
                    spaceBetween={10}
                    speed={2000}
                    direction="horizontal"
                    autoplay={{
                      delay: 0,
                      reverseDirection: true,
                    }}
                    loop={true}
                    slidesPerView={7.5}
                    freeMode={true}
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                        speed: 5000,
                      },
                      768: {
                        slidesPerView: 1,
                      },
                      1024: {
                        slidesPerView: 7.5,
                      },
                    }}
                  >
                    {brands.map((brands) => (
                      <SwiperSlide>
                        <img
                          src={`${configs.localApiUrl}${brands.brandImage}`}
                          alt="Slide 1"
                          key={brands.id}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
              <div className="u-wrapper d-block d-md-none">
                <div className="c-carousel">
                  <Swiper
                    modules={[Autoplay]}
                    direction="horizontal"
                    loop={true}
                    freeMode={true}
                    freeModeMomentum={false}
                    slidesPerView="auto"
                    spaceBetween={gap}
                    speed={4000}
                    watchSlidesProgress={true}
                    watchSlidesVisibility={true}
                    autoplay={{
                      delay: 1,
                      disableOnInteraction: false,
                    }}
                    cssMode={false}
                  >
                    {brands.map((brands) => (
                      <SwiperSlide>
                        <img
                          src={`${configs.localApiUrl}${brands.brandImage}`}
                          alt="Slide 1"
                          key={brands.id}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
              <div className="u-wrapper d-block d-md-none">
                <div className="c-carousel">
                  <Swiper
                    modules={[Autoplay]}
                    direction="horizontal"
                    loop={true}
                    freeMode={true}
                    freeModeMomentum={false}
                    slidesPerView="auto"
                    spaceBetween={gap}
                    speed={4000}
                    watchSlidesProgress={true}
                    watchSlidesVisibility={true}
                    autoplay={{
                      delay: 1,
                      reverseDirection: true,
                    }}
                    cssMode={false}
                  >
                    {brands.map((brands) => (
                      <SwiperSlide>
                        <img
                          src={`${configs.localApiUrl}${brands.brandImage}`}
                          alt="Slide 1"
                          key={brands.id}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AboutChooseus;
