import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CustomLoader from "../../components/CustomeLoader";
import socialicon3 from "../../ulits/images/social-icon3.svg";
import shareicon from "../../ulits/images/share-icon.svg";
import usericon from "../../ulits/images/user_icon.svg";
import warrantyicon from "../../ulits/images/warranty_icon.svg";
import serviceicon from "../../ulits/images/service_icon.svg";
import { Modal } from "react-bootstrap";
import driveicon from "../../ulits/images/drive_icon.svg";
import { configs } from "../../config";
import Accordion from "react-bootstrap/Accordion";
import monthicon from "../../ulits/images/month_icon.svg";
import searchicon from "../../ulits/images/search_icon.svg";
import checkedicon from "../../ulits/images/checked_icon.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { homeAction } from "../../_actions/home.action";
import parse from "html-react-parser";
import { status } from "../../_constants/commonLhd";
import { Helmet } from "react-helmet";

function CarDetails() {
  const [loader, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const location = useLocation();
  const [cars, setCars] = useState(location.state || null);
  const [active, setActive] = useState("button1");
  const navigate = useNavigate();
  const [isValidate2, setIsValidate2] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const { slug } = useParams();

  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    phone: "",
    bestTime: "",
  });

  const [credentials2, setCredentials2] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const dispatch = useDispatch();

  const homeData = useSelector((state) => state.home);

  useEffect(() => {
    if (cars) {
      dispatch(homeAction.getCarDetail({ slug }));
    } else {
      setLoading(false);
    }
  }, [dispatch, slug]);

  useEffect(() => {
    if (homeData && homeData.car_details_status === status.SUCCESS) {
      setCars(homeData.car_details_data);
      setLoading(false);
    }
  }, [homeData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone" && value.length > 12) {
      return;
    }
    setCredentials((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    if (name === "phone" && value.length > 12) {
      return;
    }
    setCredentials2((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  const handleModalClose = () => {
    setModal(false);
  };
  const handleModalOpen = () => {
    setModal(true);
  };

  const handleModal2Close = () => {
    setModal2(false);
  };
  const handleModal2Open = () => {
    setModal2(true);
  };

  const validateCredentials = (isValidate) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const PhoneRegex = /^\d{10,12}$/;
    const validObj = {
      message: "",
      isValid: true,
    };
    let isValid = true;
    const retData = {
      name: validObj,
      email: validObj,
      phone: validObj,
      bestTime: validObj,
      isValid,
    };
    if (isValidate) {
      const { name, email, phone, bestTime } = credentials;

      if (!name) {
        retData.name = {
          message: "Name is required",
          isValid: false,
        };
        isValid = false;
      } else if (name && name.length <= 2) {
        retData.name = {
          message: "Name must be at least 3 characters",
          isValid: false,
        };
        isValid = false;
      }

      if (!email) {
        retData.email = {
          message: "Email is required",
          isValid: false,
        };
        isValid = false;
      } else if (email && !emailRegex.test(email)) {
        retData.email = {
          message: "Enter valid email",
          isValid: false,
        };
        isValid = false;
      }

      if (!phone) {
        retData.phone = {
          message: "Phone number is required",
          isValid: false,
        };
        isValid = false;
      } else if (phone && !PhoneRegex.test(phone)) {
        retData.phone = {
          message: "Enter a valid phone number",
          isValid: false,
        };
        isValid = false;
      }

      if (!bestTime) {
        retData.bestTime = {
          message: "Best time is required",
          isValid: false,
        };
        isValid = false;
      }
      retData.isValid = isValid;
      return retData;
    }
  };

  const validateCredentials2 = (isValidate2) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const PhoneRegex = /^\d{10,12}$/;
    const validObj = {
      message: "",
      isValid: true,
    };
    let isValid = true;
    const retData = {
      name: validObj,
      email: validObj,
      phone: validObj,
      message: validObj,
      isValid,
    };
    if (isValidate2) {
      const { name, email, phone, message } = credentials2;

      if (!name) {
        retData.name = {
          message: "Name is required",
          isValid: false,
        };
        isValid = false;
      } else if (name && name.length <= 2) {
        retData.name = {
          message: "Name must be at least 3 characters",
          isValid: false,
        };
        isValid = false;
      }

      if (!email) {
        retData.email = {
          message: "Email is required",
          isValid: false,
        };
        isValid = false;
      } else if (email && !emailRegex.test(email)) {
        retData.email = {
          message: "Enter valid email",
          isValid: false,
        };
        isValid = false;
      }

      if (!phone) {
        retData.phone = {
          message: "Phone number is required",
          isValid: false,
        };
        isValid = false;
      } else if (phone && !PhoneRegex.test(phone)) {
        retData.phone = {
          message: "Enter a valid phone number",
          isValid: false,
        };
        isValid = false;
      }

      if (!message) {
        retData.message = {
          message: "Message is required",
          isValid: false,
        };
        isValid = false;
      }

      retData.isValid = isValid;
      return retData;
    }
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    setIsValidate2(true);
    const error2 = validateCredentials2(true);
    if (error2.isValid) {
      dispatch(homeAction.carInquiry(credentials2));
      setIsValidate2(false);
      handleModal2Close();
    }
    setCredentials2({});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsValidate(true);
    const error = validateCredentials(true);
    if (error.isValid) {
      dispatch(homeAction.testDrive(credentials));
      navigate("/car-listing");
    }
  };

  const error = validateCredentials(isValidate);
  const error2 = validateCredentials2(isValidate2);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          image: window.location.href,
          title: "Check this out!",
          text: "Visit this amazing website!",
          url: window.location.href,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      alert("Sharing is not supported on this device.");
    }
  };

  return (
    <>
      <Helmet>
        <meta name="description" content={cars && cars.metaDescription} />
        <meta name="keywords" content={cars && cars.metaKeywords} />
      </Helmet>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="car-listing-section car-details">
            <div className="container">
              <div className="modern-filter-listing">
                <div className="car-listing-left d-none d-md-block">
                  <h5>£{cars && cars.mileage}</h5>
                  <div className="details-box">
                    <div className="inner-title-text">
                      <h3>BODY</h3>
                      <p>{cars && cars.model && cars.model.toUpperCase()}</p>
                    </div>
                    <div className="inner-title-text">
                      <h3>MILEAGE</h3>
                      <p>{cars && cars.mileage} MILES</p>
                    </div>
                    <div className="inner-title-text">
                      <h3>FUEL TYPE</h3>
                      <p>{cars && cars.model && cars.fuelType.toUpperCase()}</p>
                    </div>
                    <div className="inner-title-text">
                      <h3>ENGINE</h3>
                      <p>{cars && cars.engine}</p>
                    </div>
                    <div className="inner-title-text">
                      <h3>TRANSMISSION</h3>
                      <p>
                        {cars && cars.model && cars.transmission.toUpperCase()}
                      </p>
                    </div>
                    <div className="inner-title-text">
                      <h3>EXTERIOR COLOUR</h3>
                      <p>
                        {cars && cars.model && cars.exteriorColor.toUpperCase()}
                      </p>
                    </div>
                    <div className="inner-title-text">
                      <h3>INTERIOR COLOUR</h3>
                      <p>
                        {cars && cars.model && cars.interiorColor.toUpperCase()}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="car-listing-right">
                  <div className="car-listing-right-top-left-text">
                    <div className="car-top-section">
                      <ul>
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        /
                        <li>
                          <Link to="/car-listing">Car Listing</Link>
                        </li>
                        /
                        <li>
                          <Link to="#">{cars && cars.details}</Link>
                        </li>
                      </ul>
                    </div>
                    <h1>{cars && cars.details}</h1>
                  </div>
                  <div className="car-list-btn">
                    <button
                      onClick={handleModalOpen}
                      className="primary-btn sm"
                    >
                      <img src={socialicon3} alt="" />
                      <span>SCHEDULT TEST DRIVE</span>
                    </button>
                    <button className="primary-btn sm" onClick={handleShare}>
                      <img src={shareicon} alt="" />
                      <Link style={{ textDecoration: "none" }}>
                        <span> SHARE THIS</span>
                      </Link>
                      {/* SHARE THIS */}
                    </button>
                  </div>
                  <div className="car-list-img">
                    {/* {cars.image && cars.image[0] !== undefined ? ( */}
                    <img
                      src={`${configs.localApiUrl}${cars && cars.image[0]}`}
                      alt=""
                    />
                    {/* ) : ( */}
                    {/* <p>No image available</p> */}
                    {/* )} */}
                  </div>
                  {/* <p>{cars.description}</p> */}
                  <div className="swiper-container">
                    <div className="swiper-button-prev custom-prev"></div>
                    <div className="swiper-button-next custom-next"></div>
                    <Swiper
                      spaceBetween={20}
                      slidesPerView={4}
                      speed={1000}
                      loop={false}
                      navigation={{
                        nextEl: ".custom-next",
                        prevEl: ".custom-prev",
                      }}
                      modules={[Navigation]}
                      breakpoints={{
                        320: {
                          slidesPerView: 3.2,
                          spaceBetween: 10,
                        },
                        768: {
                          slidesPerView: 4,
                          spaceBetween: 20,
                        },
                      }}
                    >
                      {cars &&
                        cars.image &&
                        cars.image.map((item, index) => (
                          <SwiperSlide key={index}>
                            <img src={`${configs.localApiUrl}${item}`} alt="" />
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>

                  <div className="group-btn">
                    <button
                      className={active === "button1" ? "active" : ""}
                      onClick={handleModal2Open}
                    >
                      VEHICLE INQUIRY
                    </button>
                    {/* TO DO */}
                    {/* <button
                      className={active === "button2" ? "active" : ""}
                      onClick={() => setActive("button2")}
                    >
                      PART EXCHANGE
                    </button> */}
                  </div>
                  <div className="stm-accordion-single-unit right-hand-drive">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>DESCRIPTION</Accordion.Header>
                        <Accordion.Body>
                          {/* {parse(cars && cars.description)} */}
                          {cars && cars.description ? (
                            parse(cars.description)
                          ) : (
                            <p>No description available</p>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          ADDITIONAL INFORMATION
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="information">
                            <div className="addi-information">
                              <div className="box">
                                <div className="information-text">
                                  <img src={usericon} alt="" />
                                  <p>{cars && cars.carOwner}</p>
                                </div>
                              </div>
                              <div className="box">
                                <div className="information-text">
                                  <img src={monthicon} alt="" />
                                  <p>{cars && cars.carMot}</p>
                                </div>
                              </div>
                              <div className="box">
                                <div className="information-text">
                                  <img src={warrantyicon} alt="" />
                                  <p>{cars && cars.carWarranty}</p>
                                </div>
                              </div>
                              <div className="box">
                                <div className="information-text">
                                  {cars && cars.serviceHistory ? (
                                    <>
                                      <img src={serviceicon} alt="" />
                                      Service History
                                    </>
                                  ) : null}
                                </div>
                              </div>
                              <div className="box">
                                <div className="information-text">
                                  {cars && cars.preInspection ? (
                                    <>
                                      <img src={searchicon} alt="" />
                                      Pre Inspection
                                    </>
                                  ) : null}
                                </div>
                              </div>
                              <div className="box">
                                <div className="information-text">
                                  {cars && cars.hpiChecked ? (
                                    <>
                                      <img
                                        src={checkedicon}
                                        alt="Checked Icon"
                                      />
                                      HPI Checked
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="addi-information">
                              {/* <div className="box">
                                <div className="information-text">
                                  {cars.preInspection ? (
                                    <>
                                      <img src={searchicon} alt="" />
                                      Pre Inspection
                                    </>
                                  ) : null}
                                </div>
                              </div> */}
                              {/* <div className="box">
                                <div className="information-text">
                                  <img src={searchicon} alt="" />
                                  {cars.preInspection ? "Pre Inspection" : ""} */}
                              {/* <p>Pre Inspection</p> */}
                              {/* <p>{cars && cars.preInspection} {" "} */}
                              {/* <FontAwesomeIcon
                                      icon={
                                        cars.preInspection
                                          ? faCircleCheck
                                          : faCircleXmark
                                      }
                                      style={{
                                        color: cars.preInspection
                                          ? "#69f00f"
                                          : "#ff4d4d",
                                        fontSize: "22px",
                                      }}
                                    /> */}{" "}
                              {/* </p> */}
                              {/* </div>
                              </div> */}
                            </div>
                            <div className="addi-information">
                              {/* <div className="box">
                                <div className="information-text">
                                  <img src={serviceicon} alt="" />
                                  {cars.serviceHistory
                                    ? "Full Service History"
                                    : "No Service History"} */}
                              {/* <p>
                                    Full Service History */}
                              {/* {" "}
                                    <FontAwesomeIcon
                                      icon={
                                        cars.serviceHistory
                                          ? faCircleCheck
                                          : faCircleXmark
                                      }
                                      style={{
                                        color: cars.serviceHistory
                                          ? "#69f00f"
                                          : "#ff4d4d",
                                        fontSize: "22px",
                                      }}
                                    />{" "} */}
                              {/* </p> */}
                              {/* </div> */}
                              {/* </div> */}
                              {/* <div className="box">
                                <div className="information-text">
                                  {cars.hpiChecked ? (
                                    <>
                                      <img
                                        src={checkedicon}
                                        alt="Checked Icon"
                                      />
                                      HPI Checked
                                    </>
                                  ) : null}
                                </div>
                              </div> */}
                              {/* <p>HPI Checked */}
                              {/* {" "}
                                    <FontAwesomeIcon
                                      icon={
                                        cars.hpiChecked
                                          ? faCircleCheck
                                          : faCircleXmark
                                      }
                                      style={{
                                        color: cars.hpiChecked
                                          ? "#69f00f"
                                          : "#ff4d4d",
                                        fontSize: "22px",
                                      }}
                                    />{" "} */}
                              {/* </p> */}
                            </div>
                          </div>
                          {/* </div> */}
                          {/* </div> */}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item
                        eventKey="2"
                        className="car-listing-left-accordion d-block d-md-none"
                      >
                        <Accordion.Header>SPECIFICATION</Accordion.Header>
                        <Accordion.Body>
                          <div className="car-listing-left">
                            <h5>£{cars && cars.mileage}</h5>
                            <div className="details-box">
                              <div className="inner-title-text">
                                <h3>BODY</h3>
                                <p>
                                  {cars &&
                                    cars.model &&
                                    cars.model.toUpperCase()}
                                </p>
                              </div>
                              <div className="inner-title-text">
                                <h3>MILEAGE</h3>
                                <p>{cars && cars.mileage} MILES</p>
                              </div>
                              <div className="inner-title-text">
                                <h3>FUEL TYPE</h3>
                                <p>
                                  {cars &&
                                    cars.model &&
                                    cars.fuelType.toUpperCase()}
                                </p>
                              </div>
                              <div className="inner-title-text">
                                <h3>ENGINE</h3>
                                <p>{cars && cars.engine}</p>
                              </div>
                              <div className="inner-title-text">
                                <h3>TRANSMISSION</h3>
                                <p>
                                  {cars &&
                                    cars.model &&
                                    cars.transmission.toUpperCase()}
                                </p>
                              </div>
                              <div className="inner-title-text">
                                <h3>EXTERIOR COLOUR</h3>
                                <p>
                                  {cars &&
                                    cars.model &&
                                    cars.exteriorColor.toUpperCase()}
                                </p>
                              </div>
                              <div className="inner-title-text">
                                <h3>INTERIOR COLOUR</h3>
                                <p>
                                  {cars &&
                                    cars.model &&
                                    cars.interiorColor.toUpperCase()}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        show={modal}
        onHide={() => {
          handleModalClose();
        }}
        className="schedule-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="schedule-container">
            <img src={driveicon} alt="" />
            <h1>SCHEDULE A TEST DRIVE</h1>
            <p>{cars && cars.details}</p>
            <div className="schedule-form">
              <form>
                <div className="form-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={credentials.name}
                    placeholder="NAME"
                    className="form-control"
                    onChange={handleChange}
                  />
                  {error && error.name.message !== "" && (
                    <div className="error-message" style={{ color: "red" }}>
                      {error.name.message}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    id="email"
                    name="email"
                    value={credentials.email}
                    type="text"
                    placeholder="EMAIL"
                    className="form-control"
                    onChange={handleChange}
                  />
                  {error && error.email.message !== "" && (
                    <div className="error-message" style={{ color: "red" }}>
                      {error.email.message}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={credentials.phone}
                    placeholder="PHONE"
                    className="form-control"
                    onChange={handleChange}
                  />
                  {error && error.phone.message !== "" && (
                    <div className="error-message" style={{ color: "red" }}>
                      {error.phone.message}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    id="bestTime"
                    name="bestTime"
                    value={credentials.bestTime}
                    type="text"
                    placeholder="BEST TIME"
                    className="form-control"
                    onChange={handleChange}
                  />
                  {error && error.bestTime.message !== "" && (
                    <div className="error-message" style={{ color: "red" }}>
                      {error.bestTime.message}
                    </div>
                  )}
                </div>
                <button
                  className="primary-btn sm"
                  title="REQUEST"
                  onClick={handleSubmit}
                >
                  REQUEST
                </button>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={modal2}
        onHide={() => {
          handleModal2Close();
        }}
        className="schedule-modal schedule-modal2"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="schedule-container">
            <h1>VEHICLE INQUIRY</h1>
            <div className="schedule-form">
              <form>
                <div className="form-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="NAME"
                    className="form-control"
                    value={credentials2.name}
                    onChange={handleChange2}
                  />
                  {error2 && error2.name.message !== "" && (
                    <div className="error-message" style={{ color: "red" }}>
                      {error2.name.message}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    id="email"
                    name="email"
                    type="text"
                    placeholder="EMAIL"
                    className="form-control"
                    value={credentials2.email}
                    onChange={handleChange2}
                  />
                  {error2 && error2.email.message !== "" && (
                    <div className="error-message" style={{ color: "red" }}>
                      {error2.email.message}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="PHONE"
                    className="form-control"
                    value={credentials2.phone}
                    onChange={handleChange2}
                  />
                  {error2 && error2.phone.message !== "" && (
                    <div className="error-message" style={{ color: "red" }}>
                      {error2.phone.message}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    id="message"
                    name="message"
                    type="text"
                    placeholder="MESSAGE"
                    className="form-control"
                    value={credentials2.message}
                    onChange={handleChange2}
                  />
                  {error2 && error2.message.message !== "" && (
                    <div className="error-message" style={{ color: "red" }}>
                      {error2.message.message}
                    </div>
                  )}
                </div>
                <button
                  className="primary-btn sm"
                  title="REQUEST"
                  type="submit"
                  onClick={handleSubmit2}
                >
                  REQUEST
                </button>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default CarDetails;
