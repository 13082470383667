import React, { useState, useEffect } from "react";
import location_icon from "../../ulits/images/location_icon.svg";
import dateicon from "../../ulits/images/date-icon.svg";
import CustomLoader from "../../components/CustomeLoader";
import { Link, useLocation } from "react-router";
import { configs } from "../../config";

function BlogDetails() {
  const [loader, setLoading] = useState(true);
  const location = useLocation();
  const [blog, setBlogs] = useState(location.state);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 600);
  }, []);

  return (
    <>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="blog-listing-section blog-details">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <Link to="">
                    <div className="blog-listing-box">
                      <div className="blog-listing-img">
                        <img
                          src={`${configs.localApiUrl}${blog?.image}`}
                          alt=""
                        />
                      </div>
                      <div className="blog-listing-text">
                        <h1>{blog && blog.sortDescription}</h1>
                        <div className="meta-middle-unit">
                          <div className="blog-listing-date">
                            <img src={dateicon} alt="" />
                            <span>{blog && blog.createdAt}</span>
                          </div>
                          <div className="blog-listing-date">
                            <img src={location_icon} alt="location" />
                            <span>UK</span>
                          </div>
                        </div>
                        <p>{blog && blog.description}</p>
                        <Link to="#" className="btn btn-primary">
                          READ MORE
                        </Link>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            {/* <div className="blog-details-section">
              <div className="container">
                <div className="blog-listing-title text-center">
                  <h1>RELATED BLOG POSTS</h1>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-12">
                    <Link to="/blog-details">
                      <div className="blog-listing-box">
                        <div className="blog-listing-img">
                          <img
                            src={`http://localhost:5000/api/${blog?.image}`}
                            alt=""
                          />
                        </div>
                        <div className="blog-listing-text">
                          <h1>
                           {blog.sortDescription}
                          </h1>
                          <p>
                            {blog.description}
                          </p>
                          <div className="meta-middle-unit">
                            <div className="blog-listing-date">
                              <img src={dateicon} alt="" />
                              <span>{blog.createdAt}</span>
                            </div>
                            <div className="blog-listing-date">
                              <img src={location_icon} alt="location" />
                              <span>UK</span>
                            </div>
                          </div>
                          <Link to="#" className="btn btn-primary">
                            READ MORE
                          </Link>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-12">
                    <Link to="/blog-details">
                      <div className="blog-listing-box">
                        <div className="blog-listing-img">
                        <img
                            src={`http://localhost:5000/api/${blog?.image}`}
                            alt=""
                          />
                        </div>
                        <div className="blog-listing-text">
                          <h1>
                            {blog.sortDescription}
                          </h1>
                          <p>
                            {blog.description}
                          </p>
                          <div className="meta-middle-unit">
                            <div className="blog-listing-date">
                              <img src={dateicon} alt="date" />
                              <span>{blog.createdAt}</span>
                            </div>
                            <div className="blog-listing-date">
                              <img src={location_icon} alt="location" />
                              <span>UK</span>
                            </div>
                          </div>
                          <Link to="#" className="btn btn-primary">
                            READ MORE
                          </Link>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-12">
                    <Link to="/blog-details">
                      <div className="blog-listing-box">
                        <div className="blog-listing-img">
                        <img
                            src={`http://localhost:5000/api/${blog?.image}`}
                            alt=""
                          />
                        </div>
                        <div className="blog-listing-text">
                          <h1>
                            {blog.sortDescription}
                          </h1>
                          <p>
                            {blog.description}.
                          </p>
                          <div className="meta-middle-unit">
                            <div className="blog-listing-date">
                              <img src={dateicon} alt="date" />
                              <span>{blog.createdAt}</span>
                            </div>
                            <div className="blog-listing-date">
                              <img src={location_icon} alt="location" />
                              <span>UK</span>
                            </div>
                          </div>
                          <Link to="#" className="btn btn-primary">
                            READ MORE
                          </Link>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
}

export default BlogDetails;
