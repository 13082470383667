import { configConstant } from "../_constants/config.constant";
import { configService } from "../_services/config.service";
import { alert } from "../_utilities/alert";

export const configAction = {
  getConfigByTenantId,
  getSeoData
};

function getConfigByTenantId() {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: configConstant.GET_CONFIG_REQUEST,
        data: null,
      })
    );
    configService 
      .getConfigByTenantId()
      .then(
        (res) => {
          if (res.Status === 200) {
            dispatch(
              dispatchFunction({
                type: configConstant.GET_CONFIG_SUCCESS,
                data: res.Result.config,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: configConstant.GET_CONFIG_FAILURE,
                data: null,
              })
            );
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: configConstant.GET_CONFIG_FAILURE,
              data: error,
            })
          );
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong!");
      });
  };
}

function getSeoData() {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: configConstant.GET_SEO_REQUEST,
        data: null,
      })
    );
    configService 
      .getSeoData()
      .then(
        (res) => {
          if (res.Status === 200) {
            dispatch(
              dispatchFunction({
                type: configConstant.GET_SEO_SUCCESS,
                data: res.Result.seo,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: configConstant.GET_SEO_FAILURE,
                data: null,
              })
            );
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: configConstant.GET_SEO_FAILURE,
              data: error,
            })
          );
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong!");
      });
  };
}

function dispatchFunction(data) {
  return {
    type: data.type,
    data: data.data,
  };
}
