import React, { useEffect, useState } from "react";
import CustomLoader from "../../components/CustomeLoader";

const Privacy = () => {
  const [loader, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);

  return (
    <>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="containts-page-section">
          <div className="container">
            <div className="text-center">
              <h1>Privacy Policy</h1>
            </div>
            <strong>Effective Date:</strong> January 1, 2025
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              scelerisque urna eu leo volutpat, a aliquet mi gravida. Curabitur
              et justo ante. Proin auctor eros ut magna consectetur, nec sodales
              enim fermentum. Sed placerat dui in libero aliquam, at condimentum
              ligula hendrerit. Fusce vehicula mollis magna, in egestas purus
              pretium eget. In id elit metus. Curabitur congue, elit at
              ullamcorper consequat, libero nunc vestibulum velit, a dignissim
              dui lectus a metus. Morbi ac leo vel arcu tincidunt dictum.
            </p>
            <h2>1. Information We Collect</h2>
            <p>
              We collect various types of information in order to provide and
              improve our services to you. The information we may collect
              includes:
            </p>
            <ul>
              <li>
                <strong>Personal Information:</strong> Lorem ipsum dolor sit
                amet, consectetur adipiscing elit.
              </li>
              <li>
                <strong>Non-Personal Information:</strong> Vivamus vestibulum,
                nulla quis volutpat vehicula, justo nulla volutpat nulla.
              </li>
              <li>
                <strong>Cookies and Tracking Technologies:</strong> Proin
                euismod risus ut volutpat aliquam, euismod turpis felis
                vehicula.
              </li>
            </ul>
            <h2>2. How We Use Your Information</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              vulputate augue id eros vehicula, sit amet maximus libero sodales.
              Nulla facilisi. Quisque consequat velit non orci vehicula, vel
              rutrum purus auctor.
            </p>
            <ul>
              <li>To provide, operate, and improve our services.</li>
              <li>
                To communicate with you, respond to your inquiries, and send
                service-related updates.
              </li>
              <li>To process transactions and manage payments.</li>
              <li>
                To personalize your experience and offer tailored content or
                promotions.
              </li>
            </ul>
            <h2>3. How We Share Your Information</h2>
            <p>
              Nullam interdum lorem et facilisis ullamcorper. Nunc fringilla
              nunc sit amet erat suscipit cursus. Duis auctor sem non odio
              tincidunt, ut fermentum nunc tincidunt.
            </p>
            <ul>
              <li>
                <strong>Service Providers:</strong> Lorem ipsum dolor sit amet,
                consectetur adipiscing elit.
              </li>
              <li>
                <strong>Legal Compliance:</strong> Curabitur congue et lorem ac
                malesuada.
              </li>
              <li>
                <strong>Business Transfers:</strong> Nunc fringilla nunc sit
                amet erat suscipit cursus.
              </li>
            </ul>
            <h2>4. Data Security</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
              venenatis dolor magna, nec cursus enim laoreet ac. Donec sed orci
              felis. Vestibulum cursus metus ut ex facilisis, eu fermentum ante
              varius.
            </p>
            <h2>5. Your Choices</h2>
            <p>
              Donec eget ligula in sapien iaculis tempus non vel ligula. Morbi
              vulputate risus in erat mollis, et auctor justo volutpat.
            </p>
            <ul>
              <li>
                <strong>Opt-out of Marketing Communications:</strong> Ut
                vehicula euismod metus id condimentum.
              </li>
              <li>
                <strong>Access and Update Your Information:</strong> Curabitur
                posuere libero non purus fermentum, vel fringilla libero
                eleifend.
              </li>
              <li>
                <strong>Cookies:</strong> Etiam hendrerit magna ut est
                vulputate, non efficitur nisi fermentum.
              </li>
            </ul>
            <h2>6. Third-Party Websites</h2>
            <p>
              Integer nec odio libero. Aenean varius, lectus at aliquet
              malesuada, ante libero auctor nisi, eget malesuada nisi libero non
              ipsum.
            </p>
            <h2>7. Children’s Privacy</h2>
            <p>
              Nulla at felis non eros facilisis vestibulum. Morbi eget libero
              orci. Vivamus euismod volutpat libero non egestas. Fusce congue
              enim ac sapien laoreet, in ultricies felis feugiat.
            </p>
            <h2>8. Changes to This Privacy Policy</h2>
            <p>
              Morbi a facilisis ligula, ac lobortis felis. Sed interdum libero
              ut tortor gravida, non tincidunt nulla cursus. Aenean non velit et
              libero tempus tempor.
            </p>
            <h2>9. Contact Us</h2>
            <p>
              Quisque eget turpis sit amet lectus dictum aliquet. Nunc in purus
              vel elit congue lacinia. Donec suscipit est a sollicitudin
              feugiat. Sed sed mauris tincidunt, varius felis in, convallis
              purus.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Privacy;
