import React, { useState, useEffect } from "react";
import gmailicon from "../ulits/images/gmail-icon.svg";
import callicon from "../ulits/images/call-icon.svg";
import { Link } from "react-router-dom";
import arrowwhite from "../ulits/images/arrow_icon.svg";
import { configs } from "../config";

function Header(props) {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [config, setConfig] = useState({});
  const [activeItem, setActiveItem] = useState();

  useEffect(() => {
    setConfig(props?.configData);
  }, [props?.configData]);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  const Dropdown = () => {
    setIsOpen(!isOpen);
  };

  const ToggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const handleDropdownClick = (item) => {
    setActiveItem((prev) => (prev === item ? "" : item));
  };

  // const dispatch = useDispatch();
  // const configData = useSelector((state) => state.config);

  // useEffect(() => {
  //   dispatch(configAction.getConfigByTenantId());
  // }, []);

  // useEffect(() => {
  //   if (configData && configData.config_status === status.SUCCESS) {
  //     setConfig(configData.config_data);
  //     localStorage.setItem("config", JSON.stringify(configData.config_data));
  //   }
  // }, [configData.config_data]);

  return (
    <div className="site-header">
      {config && Object.keys(config).length > 0 && (
        <>
          <div className="header-top">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-10">
                  <div className="header-top-left">
                    <Link to="/">
                      <img
                        src={`${configs.localApiUrl}${config?.Header_logo}`}
                        alt="header-logo"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-2">
                  <div className="header-top-center">
                    <ul>
                      <li>
                        <Link
                          to="#"
                          className={activeIndex === "BUY" ? "active" : ""}
                          onClick={() => handleItemClick("BUY")}
                        >
                          BUY
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className={activeIndex === "SELL" ? "active" : ""}
                          onClick={() => handleItemClick("SELL")}
                        >
                          SELL
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className={activeIndex === "MORE" ? "active" : ""}
                          onClick={() => handleItemClick("MORE")}
                        >
                          MORE
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="sidebar">
                    <button
                      className={`menu-button ${isOpen == true ? "open" : ""}`}
                      onClick={ToggleSidebar}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                    <nav className={`menu ${isOpen == true ? "open" : ""}`}>
                      <div className="sd-header">
                        <div className="menu-close" onClick={ToggleSidebar}>
                          <img src={arrowwhite} alt="Close Menu" />
                        </div>
                      </div>
                      <div className="hide-desk">
                        <div className="meno-menu">
                          <div className="social-icon">
                            <ul className="list-unstyled">
                              <li>
                                <Link
                                  to={`mailto:${
                                    config && config.Support_Email
                                  }`}
                                >
                                  <img src={gmailicon} alt="" />
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={`tel:${config && config?.Contact_Number}`}
                                >
                                  <img src={callicon} alt="" />
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <ul className="mobo-menu">
                            <li>
                              <Link
                                to="#"
                                className={
                                  activeIndex === "BUY" ? "active" : ""
                                }
                                onClick={() => {
                                  handleItemClick("BUY");
                                  ToggleSidebar();
                                }}
                              >
                                BUY
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className={
                                  activeIndex === "SELL" ? "active" : ""
                                }
                                onClick={() => {
                                  handleItemClick("SELL");
                                  ToggleSidebar();
                                }}
                              >
                                SELL
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className={
                                  activeIndex === "MORE" ? "active" : ""
                                }
                                onClick={() => {
                                  handleItemClick("MORE");
                                  ToggleSidebar();
                                }}
                              >
                                MORE
                              </Link>
                            </li>
                            {/* <li>
                          <Link
                            to="/european-hpi"
                            className={
                              activeIndex === "EUROPEAN HPI CHECKS"
                                ? "active"
                                : ""
                            }
                            onClick={() => {
                              handleItemClick("EUROPEAN HPI CHECKS");
                            }}
                          ></Link>
                          <div
                            className={`dropdown ${
                              activeItem === "EUROPEAN HPI CHECKS"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleDropdownClick("EUROPEAN HPI CHECKS");
                              ToggleSidebar();
                            }}
                          >
                            <div className="dropdown__text">
                              EUROPEAN HPI CHECKS
                            </div>
                            {activeItem === "EUROPEAN HPI CHECKS" && (
                              <div className="dropdown__items">
                                <div className="dropdown__item">
                                  <Link to="/order-spanish">
                                    ORDER A SPANISH HPI CHECK
                                  </Link>
                                </div>
                                <div className="dropdown__item">
                                  <Link to="/order-french">
                                    ORDER A FRENCH HPI CHECK
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                        </li> */}
                            <li>
                              <div
                                className={`dropdown ${
                                  activeItem === "EUROPEAN HPI CHECKS"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleDropdownClick("EUROPEAN HPI CHECKS")
                                }
                              >
                                <div className="dropdown__text">
                                  EUROPEAN HPI CHECKS
                                </div>
                                {activeItem === "EUROPEAN HPI CHECKS" && (
                                  <div className="dropdown__items">
                                    <div className="dropdown__item">
                                      <Link
                                        to="/order-spanish"
                                        onClick={() => {
                                          handleItemClick(
                                            "EUROPEAN HPI CHECKS"
                                          );
                                          ToggleSidebar();
                                        }}
                                      >
                                        ORDER A SPANISH HPI CHECK
                                      </Link>
                                    </div>
                                    <div className="dropdown__item">
                                      <Link
                                        to="/order-french"
                                        onClick={() => {
                                          handleItemClick(
                                            "EUROPEAN HPI CHECKS"
                                          );
                                          ToggleSidebar();
                                        }}
                                      >
                                        ORDER A FRENCH HPI CHECK
                                      </Link>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </li>
                            <li>
                              <Link
                                to="/european-insurance"
                                className={
                                  activeIndex === "EUROPEAN INSURANCE"
                                    ? "active"
                                    : ""
                                }
                                onClick={() => {
                                  handleItemClick("EUROPEAN INSURANCE");
                                  ToggleSidebar();
                                }}
                              >
                                EUROPEAN INSURANCE
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/finance"
                                className={
                                  activeIndex === "FINANCE" ? "active" : ""
                                }
                                onClick={() => {
                                  handleItemClick("FINANCE");
                                  ToggleSidebar();
                                }}
                              >
                                FINANCE
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-0">
                  <div className="header-top-right">
                    <ul>
                      <li>
                        <Link to={`mailto:${config && config.Support_Email}`}>
                          <img src={gmailicon} alt="" />
                        </Link>
                      </li>
                      <li>
                        <Link to={`tel:${config && config?.Contact_Number}`}>
                          <img src={callicon} alt="" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-bottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                  <div className="header-bottom-center">
                    <ul>
                      <li className="dropdown">
                        <Link
                          to="#"
                          className={
                            activeIndex === "EUROPEAN HPI CHECKS"
                              ? "active"
                              : ""
                          }
                        >
                          EUROPEAN HPI CHECKS
                        </Link>
                        <div className="dropdown-content">
                          <ul>
                            <li>
                              <Link to="/order-spanish">
                                ORDER A SPANISH HPI CHECK
                              </Link>
                            </li>
                            <li>
                              <Link to="/order-french">
                                ORDER A FRENCH HPI CHECK
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li
                        className={
                          activeIndex === "EUROPEAN INSURANCE" ? "active" : ""
                        }
                        onClick={() => handleItemClick("EUROPEAN INSURANCE")}
                      >
                        <Link to="/european-insurance">EUROPEAN INSURANCE</Link>
                      </li>
                      <li
                        className={activeIndex === "FINANCE" ? "active" : ""}
                        onClick={() => handleItemClick("FINANCE")}
                      >
                        <Link to="/finance">FINANCE</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Header;
