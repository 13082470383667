import React, { useState, useEffect } from "react";
import CustomLoader from "../../components/CustomeLoader";
import homecarimg from "../../ulits/images/about-car-img2.png";
import { Link, useNavigate } from "react-router-dom";
import googlemap from "../../ulits/images/google-map.png";
import locationicon from "../../ulits/images/location-icon.svg";
import callicon from "../../ulits/images/call-icon.svg";
import gmailicon from "../../ulits/images/gmail-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { contactAction } from "../../_actions/contact.action";
import { status } from "../../_constants/commonLhd";
import { configs } from "../../config";
import { Helmet } from "react-helmet";
import { configAction } from "../../_actions";

function Contact() {
  const [loader, setLoading] = useState(true);
  const [activeLink, setActiveLink] = useState("UK OFFICE");
  const [credentials, setCredentials] = useState("");
  const [office, setOffice] = useState("");
  const [seo, setSeo] = useState({})
  const [isValidate, setIsValidate] = useState(false);
  const dispatch = useDispatch();
  const config = localStorage.getItem("config")
    ? JSON.parse(localStorage.getItem("config"))
    : null;
  const [formData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const seoData = useSelector((state) => state.config);

  useEffect(() => {
    if (seoData && seoData.seo_status === status.SUCCESS) {
      setSeo(seoData.seo_data);
    }
  }, [seoData]);

  const handleClick = (index) => {
    setActiveLink(index);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone" && value.length > 12) {
      return;
    }
    setCredentials((prevData) => ({
      ...prevData,
      [name]: value,
      [e.target.name]: value,
    }));
  };



  // const contactData = useSelector((state) => state.Contact);

  const navigate = useNavigate();

  const validateCredentials = (isValidate) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const PhoneRegex = /^\d{10,12}$/;
    const validObj = {
      message: "",
      isValid: true,
    };

    let isValid = true;
    const retData = {
      name: validObj,
      email: validObj,
      phone: validObj,
      message: validObj,
      isValid,
    };

    if (isValidate) {
      const { name, email, phone, message } = credentials;

      if (!name) {
        retData.name = {
          message: "Name is required",
          isValid: false,
        };
        isValid = false;
      } else if (name && name.length <= 2) {
        retData.name = {
          message: "Name must be at least 3 characters",
          isValid: false,
        };
        isValid = false;
      }

      if (!email) {
        retData.email = {
          message: "Email is required",
          isValid: false,
        };
        isValid = false;
      } else if (!emailRegex.test(email)) {
        retData.email = {
          message: "Enter a valid email address",
          isValid: false,
        };
        isValid = false;
      }

      if (!phone) {
        retData.phone = {
          message: "Phone Number is required",
          isValid: false,
        };
        isValid = false;
      } else if (!PhoneRegex.test(phone)) {
        retData.phone = {
          message: "Enter a valid phone number",
          isValid: false,
        };
        isValid = false;
      }

      if (!message) {
        retData.message = {
          message: "Message is required",
          isValid: false,
        };
        isValid = false;
      }

      retData.isValid = isValid;
      return retData;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsValidate(true);
    const error = validateCredentials(true);
    if (error.isValid) {
      dispatch(contactAction.contact(credentials));
      navigate("/");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 600);
  }, []);

  const officeData = useSelector((state) => state.contact);

  useEffect(() => {
    if (officeData && officeData.office_status === status.SUCCESS) {
      setOffice(officeData.office_data);
    }
  }, [officeData]);

  useEffect(() => {
    dispatch(contactAction.office());
  }, [dispatch]);

  const error = validateCredentials(isValidate);

  return (
    <>
    <Helmet>
      <meta
        name="description"
        content={seo && seo.Contact_Meta_Description}
      />
      <meta name="keywords" content={seo && seo.Contact_Meta_Keywords} />
    </Helmet>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="contact-us-section">
            <div className="blue-bg">
              <div className="contact-us-left">
                <div className="container">
                  <h2>CONTACT US</h2>
                  {/* {credentials &&(
                    <p style={{ color: "green" }}>
                      Form submitted successfully!
                    </p>
                  )} */}
                  <div className="form-container" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={credentials.name}
                        onChange={handleChange}
                        placeholder="NAME"
                        required
                        className="form-control"
                      />
                      {error && error.name.message !== "" && (
                        <div className="error-message" style={{ color: "red" }}>
                          {error.name.message}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={credentials.email}
                        onChange={handleChange}
                        placeholder="EMAIL"
                        // required
                        className="form-control"
                      />
                      {error && error.email.message !== "" && (
                        <div className="error-message" style={{ color: "red" }}>
                          {error.email.message}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={credentials.phone}
                        onChange={handleChange}
                        placeholder="Phone No"
                        // required
                        className="form-control"
                      />
                      {error && error.phone.message !== "" && (
                        <div className="error-message" style={{ color: "red" }}>
                          {error.phone.message}
                        </div>
                      )}
                    </div>
                    <div className="form-group textarea">
                      <textarea
                        id="message"
                        name="message"
                        value={credentials.message}
                        onChange={handleChange}
                        placeholder="MESSAGE"
                        // required
                      />
                      {error && error.message.message !== "" && (
                        <div className="error-message" style={{ color: "red" }}>
                          {error.message.message}
                        </div>
                      )}
                    </div>
                    <button
                      className="primary-btn sm"
                      type="send"
                      title="Send"
                      onClick={handleSubmit}
                    >
                      SEND
                      <div className="arrow-icon"></div>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-image-right">
                <img
                  src={`${configs.localApiUrl}${config?.Contact_image}`}
                  alt="home-col-1"
                />
              </div>
            </div>
            <div className="contact-us-right">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <div className="contact-us-right-content">
                      <ul>
                        <li
                          className={activeLink === "UK OFFICE" ? "active" : ""}
                          onClick={() => handleClick("UK OFFICE")}
                        >
                          <Link to="#">UK OFFICE</Link>
                        </li>
                        <li
                          className={
                            activeLink === "SPANISH OFFICE" ? "active" : ""
                          }
                          onClick={() => handleClick("SPANISH OFFICE")}
                        >
                          <Link to="#">SPANISH OFFICE</Link>
                        </li>
                        <li
                          className={
                            activeLink === "FRANCE OFFICE" ? "active" : ""
                          }
                          onClick={() => handleClick("FRANCE OFFICE")}
                        >
                          <Link to="#">FRANCE OFFICE</Link>
                        </li>
                      </ul>
                      <div className="contact-details">
                        {activeLink === "UK OFFICE" && (
                          <div className="contact-item">
                            <div className="map-img">
                              <iframe
                                src={office.Uk_office_map}
                                title="description"
                                style={{ height: 400 }}
                              ></iframe>
                            </div>
                            <div className="contact-info">
                              <h2>UK OFFICE</h2>
                              <ul>
                                <li>
                                  <Link to="#">
                                    <img src={locationicon} alt="" /> ADDRESS
                                    <div className="contact-address">
                                      <span>
                                        {office && office.Uk_office_address}
                                      </span>
                                    </div>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="tel:0161 620 2150">
                                    <img src={callicon} alt="" /> FREE PHONE
                                    <span>
                                      {office && office.Uk_office_free_phone}
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="tel:0161 620 2150">
                                    <img src={callicon} alt="" /> LANDLINE
                                    <span>
                                      {office && office.Uk_office_landLine}
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="tel:0161 620 2150">
                                    <img src={callicon} alt="" /> MOBILE
                                    <span>
                                      {office && office.Uk_office_mobile}
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="mailto:lhdcarshowroom@gmail.com">
                                    <img src={gmailicon} alt="" /> EMAIL
                                    <span>
                                      {office && office.Uk_office_email}
                                    </span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                        {activeLink === "SPANISH OFFICE" && (
                          <div className="contact-item">
                            <div className="map-img">
                              <iframe
                                src={office.Spanish_office_map}
                                title="description"
                                style={{ height: 400 }}
                              ></iframe>
                            </div>
                            <div className="contact-info">
                              <h2>SPANISH OFFICE</h2>
                              <ul>
                                <li>
                                  <Link to="#">
                                    <img src={locationicon} alt="" /> ADDRESS
                                    <div className="contact-address">
                                      <span>
                                        {office &&
                                          office.Spanish_office_address}
                                      </span>
                                    </div>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="tel:123456789">
                                    <img src={callicon} alt="" />
                                    FREE PHONE
                                    <span>
                                      {office &&
                                        office.Spanish_office_free_phone}
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="tel:0161 620 2150">
                                    <img src={callicon} alt="" /> LANDLINE
                                    <span>
                                      {office && office.spanish_office_landLine}
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="tel:0161 620 2150">
                                    <img src={callicon} alt="" /> MOBILE
                                    <span>
                                      {office && office.Spanish_office_mobile}
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="mailto:lhdcarshowroom@gmail.com">
                                    <img src={gmailicon} alt="" /> EMAIL
                                    <span>
                                      {office && office.Spanish_office_email}
                                    </span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                        {activeLink === "FRANCE OFFICE" && (
                          <div className="contact-item">
                            <div className="map-img">
                              <iframe
                                src={office.France_office_map}
                                title="description"
                                style={{ height: 400 }}
                              ></iframe>
                              {/* <img
                                src={`${configs.localApiUrl}${config?.contactMapimage}`}
                                alt="map-img"
                                onClick={() => {
                                  if (office && office.franceEmbed_a_map) {
                                    window.open(
                                      office.franceEmbed_a_map,
                                      "_blank"
                                    );
                                  }
                                }}
                                style={{ cursor: "pointer" }}
                              /> */}
                            </div>
                            <div className="contact-info">
                              <h2>FRANCE OFFICE</h2>
                              <ul>
                                <li>
                                  <Link to="#">
                                    <img src={locationicon} alt="" /> ADDRESS
                                    <div className="contact-address">
                                      <span>
                                        {office && office.France_office_address}
                                      </span>
                                    </div>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="tel:987654321">
                                    <img src={callicon} alt="" />
                                    FREE PHONE
                                    <span>
                                      {office && office.France_free_phone}
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="tel:0161 620 2150">
                                    <img src={callicon} alt="" /> LANDLINE
                                    <span>
                                      {office && office.France_office_landLine}
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="tel:0161 620 2150">
                                    <img src={callicon} alt="" /> MOBILE
                                    <span>
                                      {office && office.France_office_mobile}
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="mailto:lhdcarshowroom@gmail.com">
                                    <img src={gmailicon} alt="" /> EMAIL
                                    <span>
                                      {office && office.France_office_email}
                                    </span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Contact;
