import React, { useState, useEffect } from "react";
import CustomLoader from "../../components/CustomeLoader";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, FreeMode, Pagination } from "swiper/modules";
import { useLocation, useNavigate } from "react-router";
import { configs } from "../../config";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { homeAction, pageAction } from "../../_actions";
import { status } from "../../_constants/commonLhd";
import { Helmet } from "react-helmet";

function AboutHistory() {
  const [loader, setLoading] = useState(true);
  const location = useLocation();
  const [brands, setBrands] = useState([]);
  const dispatch = useDispatch();
  // const page = location.state;
  const [page, setPage] = useState(location.state || null);
  const [topBanner, setTopBanner] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 600);
  }, []);

  const navigate = useNavigate();

  const pageData = useSelector((state) => state.page);

  useEffect(() => {
    if (!page) {
      dispatch(pageAction.getPages());
    } else {
      setLoading(false);
    }
  }, [page, dispatch]);

  useEffect(() => {
    if (pageData && pageData.page_status === status.SUCCESS) {
      setPage(pageData.page_data);
      setLoading(false);
    }
  }, [pageData]);

  const handleButtonClick = (aboutHistoryButtonLink) => {
    if (aboutHistoryButtonLink.startsWith("http")) {
      window.open(aboutHistoryButtonLink, "_blank");
    } else {
      navigate(aboutHistoryButtonLink);
    }
  };

  const homeData = useSelector((state) => state.home);

  useEffect(() => {
    dispatch(homeAction.getBrandsByTenantId());
  }, []);

  useEffect(() => {
    if (homeData && homeData.top_banner_status === status.SUCCESS) {
      setTopBanner(homeData.top_banner_data);
    }
  }, [homeData]);

  useEffect(() => {
    if (homeData && homeData.brands_status === status.SUCCESS) {
      setBrands(homeData.brands_data);
    }
  }, [homeData.brands_data]);

  const isDesktop = window.innerWidth > 768;
  const gap = isDesktop ? 0.0285 * window.innerWidth : 15;

  return (
    <>
       <Helmet>
        <meta name="description" content={page && page.About_history_meta_description} />
        <meta name="keywords" content={page && page.About_history_meta_keywords} />
      </Helmet>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="about-col-section first">
            <div className="col-text blue-bg order-last d-none d-md-block">
              <div className="our-courses-box">
                <div className="date-title">
                  <h1>{page && page.About_history_title}</h1>
                  {page && page.About_history_description
                    ? parse(page.About_history_description)
                    : null}
                  {/* {parse(page && page.aboutHistoryDescription)} */}
                  <button
                    className="primary-btn sm"
                    onClick={() =>
                      handleButtonClick(page.About_history_button_link)
                    }
                  >
                    {page && page.About_history_text_button}
                    <div className="arrow-icon"></div>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-text blue-bg order-last d-block d-md-none">
              <Swiper
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {topBanner &&
                  topBanner.length > 0 &&
                  topBanner.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div className="our-courses-box">
                        <div className="date-inner-title">
                          <div className="date-title">
                            <h1>{item.title}</h1>
                            {item && item.description ? (
                              parse(item.description)
                            ) : (
                              <p>No description available</p>
                            )}
                            <button
                              className="primary-btn sm"
                              onClick={() => handleButtonClick(item.buttonLink)}
                            >
                              {item.buttonText}
                              <div className="arrow-icon"></div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
            <div className="col-image">
              <img
                src={`${configs.localApiUrl}${
                  page && page.About_history_image
                }`}
                alt="home-col-1"
              />
            </div>
          </div>
          <div className="about-list-section second">
            <div className="container">
              <div className="about-item-box">
                <div className="about-list-box">
                  {page && page.About_history_blog_1
                    ? parse(page.About_history_blog_1)
                    : null}
                  {/* {parse(page && page.aboutHistoryBlog1)} */}
                </div>
                <div className="about-list-img">
                  <img
                    src={`${configs.localApiUrl}${
                      page && page.About_history_image_1
                    }`}
                    alt="about-col-2"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="brands-stock text-center">
            <div className="head-title">
              <h2>BRANDS WE WORK WITH</h2>
              <div className="u-wrapper d-none d-md-block">
                <div className="c-carousel">
                  <Swiper
                    modules={[Autoplay, FreeMode]}
                    spaceBetween={10}
                    speed={2000}
                    direction="horizontal"
                    autoplay={{
                      delay: 0,
                      disableOnInteraction: false,
                    }}
                    loop={true}
                    slidesPerView={7.5}
                    freeMode={true}
                  >
                    {brands.map((brands) => (
                      <SwiperSlide>
                        <img
                          src={`${configs.localApiUrl}${brands.brandImage}`}
                          alt="Slide 1"
                          key={brands.id}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
              <div className="u-wrapper d-none d-md-block">
                <div className="c-carousel">
                  <Swiper
                    modules={[Autoplay, FreeMode]}
                    spaceBetween={10}
                    speed={2000}
                    direction="horizontal"
                    autoplay={{
                      delay: 0,
                      reverseDirection: true,
                    }}
                    loop={true}
                    slidesPerView={7.5}
                    freeMode={true}
                  >
                    {brands.map((brands) => (
                      <SwiperSlide>
                        <img
                          src={`${configs.localApiUrl}${brands.brandImage}`}
                          alt="Slide 1"
                          key={brands.id}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
              <div className="u-wrapper d-block d-md-none">
                <div className="c-carousel">
                  <Swiper
                    modules={[Autoplay]}
                    direction="horizontal"
                    loop={true}
                    freeMode={true}
                    freeModeMomentum={false}
                    slidesPerView="auto"
                    spaceBetween={gap}
                    speed={4000}
                    watchSlidesProgress={true}
                    watchSlidesVisibility={true}
                    autoplay={{
                      delay: 1,
                      disableOnInteraction: false,
                    }}
                    cssMode={false}
                  >
                    {brands.map((brands) => (
                      <SwiperSlide>
                        <img
                          src={`${configs.localApiUrl}${brands.brandImage}`}
                          alt="Slide 1"
                          key={brands.id}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
              <div className="u-wrapper d-block d-md-none">
                <div className="c-carousel">
                  <Swiper
                    modules={[Autoplay]}
                    direction="horizontal"
                    loop={true}
                    freeMode={true}
                    freeModeMomentum={false}
                    slidesPerView="auto"
                    spaceBetween={gap}
                    speed={4000}
                    watchSlidesProgress={true}
                    watchSlidesVisibility={true}
                    autoplay={{
                      delay: 1,
                      reverseDirection: true,
                    }}
                    cssMode={false}
                  >
                    {brands.map((brands) => (
                      <SwiperSlide>
                        <img
                          src={`${configs.localApiUrl}${brands.brandImage}`}
                          alt="Slide 1"
                          key={brands.id}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AboutHistory;
