import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../components/CustomeLoader";
import milionicon from "../../ulits/images/milion_icon.svg";
import petrolicon from "../../ulits/images/petrol_icon.svg";
import manualicon from "../../ulits/images/manual_icon.svg";
import googlepay from "../../ulits/images/google-pay.svg";
import staricon from "../../ulits/images/star-img.svg";
import carimg3 from "../../ulits/images/car_img3.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay, FreeMode } from "swiper/modules";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { configAction, homeAction, pageAction } from "../../_actions";
import { status } from "../../_constants/commonLhd";
import { configs } from "../../config";
import whatsapp from "../../ulits/images/WatSapp-icon.svg";
import parse from "html-react-parser";
import tiktok from "../../ulits/images/tiktok_icon.svg";
import instagram from "../../ulits/images/Instagram_icon.svg";
import snapchat from "../../ulits/images/Snapchat_icon.svg";
import facebook from "../../ulits/images/Facebook_icon.svg";
// Import Swiper styles
import "swiper/css/free-mode";
import "swiper/css/autoplay";
import { Helmet } from "react-helmet";

function Home() {
  const [loader, setLoading] = useState(true);
  const [isValidate, setIsValidate] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
  });
  const [cars, setCars] = useState([]);
    const [seo, setSeo] = useState({})
  const [brands, setBrands] = useState([]);
  const [aboutData, setAboutData] = useState();
  const [topBanner, setTopBanner] = useState({});
  const config = localStorage.getItem("config")
    ? JSON.parse(localStorage.getItem("config"))
    : null;

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const homeData = useSelector((state) => state.home);

  useEffect(() => {
    dispatch(homeAction.getBrandsByTenantId());
    dispatch(homeAction.getCarsByTenantId());
    dispatch(homeAction.getTopBannerDetail());
  }, [dispatch]);

  const seoData = useSelector((state) => state.config);

  useEffect(() => {
    if (seoData && seoData.seo_status === status.SUCCESS) {
      setSeo(seoData.seo_data);
    }
  }, [seoData]);

  useEffect(() => {
    if (homeData && homeData.car_status === status.SUCCESS) {
      setCars(homeData.car_data);
    }
  }, [homeData]);

  useEffect(() => {
    if (homeData && homeData.brands_status === status.SUCCESS) {
      setBrands(homeData.brands_data);
    }
  }, [homeData]);

  useEffect(() => {
    if (homeData && homeData.top_banner_status === status.SUCCESS) {
      setTopBanner(homeData.top_banner_data);
    }
  }, [homeData]);

  useEffect(() => {
    if (homeData && homeData.email_status === status.SUCCESS) {
    }
  }, [homeData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 600);
  }, []);

  const validateCredentials = (isValidate) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const validObj = {
      message: "",
      isValid: true,
    };

    let isValid = true;
    const retData = {
      email: validObj,
      isValid,
    };

    if (isValidate) {
      const { email } = credentials;

      if (!email) {
        retData.email = {
          message: "Email is required",
          isValid: false,
        };
        isValid = false;
      } else if (!emailRegex.test(email)) {
        retData.email = {
          message: "Please enter a valid email address",
          isValid: false,
        };
        isValid = false;
      }

      retData.isValid = isValid;
      return retData;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const error = validateCredentials(true);
    setIsValidate(true);
    if (error.isValid) {
      dispatch(homeAction.sendEmail(credentials));
      setCredentials({ email: "" });
      setIsValidate(false);
      navigate("/");
    }
  };

  const handleButtonClick = (buttonLink) => {
    if (buttonLink.startsWith("http")) {
      window.open(buttonLink, "_blank");
    } else {
      navigate(buttonLink);
    }
  };

  const aboutChooseData = useSelector((state) => state.page);

  useEffect(() => {
    if (aboutChooseData && aboutChooseData.about_status === status.SUCCESS)
      setAboutData(aboutChooseData.about_data);
  }, [aboutChooseData]);

  useEffect(() => {
    dispatch(pageAction.getAboutChooseData());
  }, [dispatch]);

  const error = validateCredentials(isValidate);

  const isDesktop = window.innerWidth > 768;
  const gap = isDesktop ? 0.0285 * window.innerWidth : 15;

  return (
    <>
      <Helmet>
        <meta name="description" content={seo && seo.Home_Meta_Description} />
        <meta name="keywords" content={seo && seo.Home_Meta_Keywords} />
      </Helmet>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="home-col-section first">
            <div className="col-text blue-bg order-last">
              <Swiper
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {topBanner &&
                  topBanner.length > 0 &&
                  topBanner.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div className="our-courses-box">
                        <div className="date-inner-title">
                          <div className="date-title">
                            <h2>{item.title}</h2>
                            {parse(item.description)}
                            <button
                              className="primary-btn sm"
                              onClick={() => handleButtonClick(item.buttonLink)}
                            >
                              {item.buttonText}
                              <div className="arrow-icon"></div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
            <div className="col-image">
              <img
                src={`${configs.localApiUrl}${config?.Top_banner_image}`}
                alt="home-col-1"
              />
              <div className="WatSapp-icon">
                <div className="whatsapp-icon">
                  <Link
                    to={`https://wa.me/${config && config?.Contact_Number}`}
                  >
                    <img src={whatsapp} alt="whatsapp" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="home-new-section second">
            <div className="col-image">
              <div className="container">
                <div className="date-inner-title containe">
                  <div className="date-title">
                    <h1 style={{ color: "white" }}>NEW IN</h1>
                  </div>
                  <div className="box-wrapper">
                    {cars &&
                      cars
                        .sort((a, b) => b.isNew - a.isNew)
                        .slice(0, 6)
                        .map((cars, index) => (
                          <div
                            className="box"
                            key={index}
                            onClick={() =>
                              navigate(`/car-details/${cars.slug}`, {
                                state: cars,
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={`${configs.localApiUrl}${cars.image[0]}`}
                              alt="home-col-1"
                              style={{ cursor: "pointer" }}
                            />
                            <div className="box-content-inner">
                              <div className="box-content">
                                <h1>{cars && cars.details.toUpperCase()}</h1>
                                <ul>
                                  <li>
                                    <Link to="#">
                                      <img src={milionicon} alt="arrow-icon" />
                                      {cars.mileage} MILES
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#">
                                      <img src={petrolicon} alt="arrow-icon" />
                                      {cars && cars.fuelType.toUpperCase()}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#">
                                      <img src={manualicon} alt="arrow-icon" />
                                      {cars && cars.transmission.toUpperCase()}
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <button className="primary-btn sm">
                                £{cars.price}
                              </button>
                            </div>
                          </div>
                        ))}
                  </div>
                  <button
                    className="primary-btn-list"
                    onClick={() => navigate("/car-listing")}
                  >
                    FULL STOCK LIST
                  </button>
                </div>
              </div>
            </div>
            <div className="home-choose-us-section">
              <div className="container">
                <div className="heading-title">
                  <h1>{aboutData && aboutData.Title}</h1>
                </div>
                <div className="choose-us-box">
                  <div className="choose-us-wrapper">
                    <div className="choose-us-box">
                      <div className="choose-us-content">
                        <img
                          src={`${configs.localApiUrl}${
                            (aboutData && aboutData.Logo_1) || ""
                          }`}
                          alt="tab-icon"
                        />
                        {/* {aboutData && aboutData.Blog1 ? 
                        parse(aboutData && aboutData.Blog1)
                        : null} */}
                        {aboutData && typeof aboutData.Blog_1 === "string"
                          ? parse(aboutData && aboutData.Blog_1)
                          : null}
                      </div>
                      <div className="choose-us-icon">
                        <img
                          src={`${configs.localApiUrl}${
                            (aboutData && aboutData.Blog_image_1) || ""
                          }`}
                          alt="car-icon"
                        />
                      </div>
                    </div>
                    <div className="choose-us-box">
                      <div className="choose-us-content">
                        <img
                          src={`${configs.localApiUrl}${
                            aboutData && aboutData.Logo_2
                          }`}
                          alt="tab-icon"
                        />
                        {aboutData && typeof aboutData.Blog_2 === "string"
                          ? parse(aboutData && aboutData.Blog_2)
                          : null}
                      </div>
                      <div className="choose-us-icon">
                        <img
                          src={`${configs.localApiUrl}${
                            aboutData && aboutData.Blog_image_2
                          }`}
                          alt="car-icon"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="choose-us-wrapper">
                    <div className="choose-us-box">
                      <div className="choose-us-content">
                        <img
                          src={`${configs.localApiUrl}${
                            (aboutData && aboutData.Logo_3) || ""
                          }`}
                          alt="tab-icon"
                        />
                        {aboutData && typeof aboutData.Blog_3 === "string"
                          ? parse(aboutData && aboutData.Blog_3)
                          : null}
                      </div>
                      <div className="choose-us-icon">
                        <img
                          src={`${configs.localApiUrl}${
                            aboutData && aboutData.Blog_image_3
                          }`}
                          alt="car-icon"
                        />
                      </div>
                    </div>
                    <div className="choose-us-box">
                      <div className="choose-us-content">
                        <img
                          src={`${configs.localApiUrl}${
                            (aboutData && aboutData.Logo_4) || ""
                          }`}
                          alt="tab-icon"
                        />
                        {aboutData && typeof aboutData.Logo_4 === "string"
                          ? parse(aboutData && aboutData.Blog_4)
                          : null}
                      </div>
                      <div className="choose-us-icon">
                        <img
                          src={`${configs.localApiUrl}${
                            aboutData && aboutData.Blog_image_4
                          }`}
                          alt="car-icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="google-review-section">
              <div className="container">
                <div className="heading-title">
                  <h1>GOOGLE REVIEWS</h1>
                </div>
                <div className="google-review-wrapper-inner first-box">
                  <div className="google-content-inner">
                    <img
                      src={googlepay}
                      alt="google-img"
                      className="google-img"
                    />
                    <p>5 stars</p>
                    <img
                      src={staricon}
                      alt="arrow-icon"
                      className="star-icon"
                    />
                    <p>234 reviews</p>
                  </div>
                </div>
                <div className="google-review-wrapper-box">
                  <div className="u-wrapper">
                    <Swiper
                      modules={[Autoplay, FreeMode]}
                      spaceBetween={10}
                      speed={5000}
                      direction="horizontal"
                      autoplay={{
                        delay: 0,
                        disableOnInteraction: false,
                      }}
                      loop={true}
                      freeMode={true}
                      slidesPerView={3}
                      breakpoints={{
                        320: { slidesPerView: 1.1 },
                        640: { slidesPerView: 1.1 },
                        1040: { slidesPerView: 3 },
                      }}
                    >
                      <SwiperSlide>
                        <div className="google-review-box">
                          <div className="google-review-img">
                            <img
                              src={staricon}
                              alt="arrow-icon"
                              className="google-img"
                            />
                            <img
                              src={googlepay}
                              alt="google-img"
                              className="star-icon"
                            />
                          </div>
                          <div className="google-review-content">
                            <h2>1 month ago</h2>
                            <p>
                              Lorem ipsum odor amet, consectetuer adipiscing
                              elit. Justo blandit sit nunc; curae enim lectus!
                              Elit mi auctor duis ligula magnis. Congue lectus
                              rhoncus odio venenatis sem. Quam sollicitudin
                              potenti turpis torquent semper.
                            </p>
                            <h2>John Smith</h2>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="google-review-box">
                          <div className="google-review-img">
                            <img
                              src={staricon}
                              alt="arrow-icon"
                              className="google-img"
                            />
                            <img
                              src={googlepay}
                              alt="google-img"
                              className="star-icon"
                            />
                          </div>
                          <div className="google-review-content">
                            <h2>1 month ago</h2>
                            <p>
                              Lorem ipsum odor amet, consectetuer adipiscing
                              elit. Justo blandit sit nunc; curae enim lectus!
                              Elit mi auctor duis ligula magnis. Congue lectus
                              rhoncus odio venenatis sem. Quam sollicitudin
                              potenti turpis torquent semper.
                            </p>
                            <h2>John Smith</h2>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="google-review-box">
                          <div className="google-review-img">
                            <img
                              src={staricon}
                              alt="arrow-icon"
                              className="google-img"
                            />
                            <img
                              src={googlepay}
                              alt="google-img"
                              className="star-icon"
                            />
                          </div>
                          <div className="google-review-content">
                            <h2>1 month ago</h2>
                            <p>
                              Lorem ipsum odor amet, consectetuer adipiscing
                              elit. Justo blandit sit nunc; curae enim lectus!
                              Elit mi auctor duis ligula magnis. Congue lectus
                              rhoncus odio venenatis sem. Quam sollicitudin
                              potenti turpis torquent semper.
                            </p>
                            <h2>John Smith</h2>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="google-review-box">
                          <div className="google-review-img">
                            <img
                              src={staricon}
                              alt="arrow-icon"
                              className="google-img"
                            />
                            <img
                              src={googlepay}
                              alt="google-img"
                              className="star-icon"
                            />
                          </div>
                          <div className="google-review-content">
                            <h2>1 month ago</h2>
                            <p>
                              Lorem ipsum odor amet, consectetuer adipiscing
                              elit. Justo blandit sit nunc; curae enim lectus!
                              Elit mi auctor duis ligula magnis. Congue lectus
                              rhoncus odio venenatis sem. Quam sollicitudin
                              potenti turpis torquent semper.
                            </p>
                            <h2>John Smith</h2>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="google-review-box">
                          <div className="google-review-img">
                            <img
                              src={staricon}
                              alt="arrow-icon"
                              className="google-img"
                            />
                            <img
                              src={googlepay}
                              alt="google-img"
                              className="star-icon"
                            />
                          </div>
                          <div className="google-review-content">
                            <h2>1 month ago</h2>
                            <p>
                              Lorem ipsum odor amet, consectetuer adipiscing
                              elit. Justo blandit sit nunc; curae enim lectus!
                              Elit mi auctor duis ligula magnis. Congue lectus
                              rhoncus odio venenatis sem. Quam sollicitudin
                              potenti turpis torquent semper.
                            </p>
                            <h2>John Smith</h2>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="google-review-box">
                          <div className="google-review-img">
                            <img
                              src={staricon}
                              alt="arrow-icon"
                              className="google-img"
                            />
                            <img
                              src={googlepay}
                              alt="google-img"
                              className="star-icon"
                            />
                          </div>
                          <div className="google-review-content">
                            <h2>1 month ago</h2>
                            <p>
                              Lorem ipsum odor amet, consectetuer adipiscing
                              elit. Justo blandit sit nunc; curae enim lectus!
                              Elit mi auctor duis ligula magnis. Congue lectus
                              rhoncus odio venenatis sem. Quam sollicitudin
                              potenti turpis torquent semper.
                            </p>
                            <h2>John Smith</h2>
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
            <div className="join-our-section">
              <div className="join-title">
                <div className="container join-title-inner">
                  <h1>JOIN OUR NEWSLETTER NOW</h1>
                  <form className="join-form" onSubmit={handleSubmit}>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={credentials.email}
                      onChange={handleChange}
                      placeholder="Enter Your Email Address"
                      className="form-control"
                      required
                    />
                    <div
                      type="submit"
                      className="arrow-icons"
                      onClick={handleSubmit}
                    ></div>
                  </form>
                  {error && error.email.message !== "" && (
                    <div className="error-message" style={{ color: "red" }}>
                      {error.email.message}
                    </div>
                  )}
                </div>
                <div className="join-img">
                  <img
                    src={`${configs.localApiUrl}${config?.Join_image}`}
                    alt="join-img"
                  />
                </div>
              </div>
            </div>
            <div className="follow-us-section">
              <div className="col-image">
                <img src={carimg3} alt="follow-img" />
              </div>
              <div className="col-content">
                <h2>FOLLOW US ON SOCIALS</h2>
                <ul>
                  <li>
                    <Link
                      to={
                        config && config?.Tiktok_Url ? config?.Tiktok_Url : "#"
                      }
                    >
                      <img src={tiktok} alt="tiktok_icon" />
                      {/* <img
                        src={`${configs.localApiUrl}${config?.tiktokLogo}`}
                        alt="tiktok_icon"
                        onClick={() => window.open(config?.Tiktok_Url)}
                      /> */}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={
                        config && config?.Snapchat_Url
                          ? config?.Snapchat_Url
                          : "#"
                      }
                    >
                      <img src={snapchat} alt="snapchat_icon" />
                      {/* <img
                        src={`${configs.localApiUrl}${config?.snapchatLogo}`}
                        alt="snapchat_icon"
                        onClick={() => window.open(config?.Snapchat_Url)}
                      />  */}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={
                        config && config?.Instagram_Url
                          ? config?.Instagram_Url
                          : "#"
                      }
                    >
                      <img src={instagram} alt="instagram_icon" />
                      {/* <img
                        src={`${configs.localApiUrl}${config?.instagramLogo}`}
                        alt="instagram_icon"
                        onClick={() => window.open(config?.Instagram_Url)}
                      />  */}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={
                        config && config?.Facebook_Url
                          ? config?.Facebook_Url
                          : "#"
                      }
                    >
                      <img src={facebook} alt="facebook_icon" />
                      {/* <img
                        src={`${configs.localApiUrl}${config?.fecebookLogo}`}
                        alt="facebook_icon"
                        onClick={() => window.open(config?.Facebook_Url)}
                      />  */}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="brands-stock text-center">
              <div className="head-title">
                <h2>BRANDS WE WORK WITH</h2>
                <div className="u-wrapper d-none d-md-block">
                  <div className="c-carousel">
                    <Swiper
                      modules={[Autoplay]}
                      spaceBetween={10}
                      speed={2000}
                      direction="horizontal"
                      autoplay={{
                        delay: 0,
                        disableOnInteraction: false,
                      }}
                      loop={true}
                      slidesPerView={7.5}
                      freeMode={true}
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                          speed: 5000,
                        },
                        768: {
                          slidesPerView: 1,
                        },
                        1024: {
                          slidesPerView: 7.5,
                        },
                      }}
                    >
                      {brands.map((brands) => (
                        <SwiperSlide>
                          <img
                            src={`${configs.localApiUrl}${brands.brandImage}`}
                            alt="Slide 1"
                            key={brands.id}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
                <div className="u-wrapper d-none d-md-block">
                  <div className="c-carousel">
                    <Swiper
                      modules={[Autoplay]}
                      spaceBetween={10}
                      speed={2000}
                      direction="horizontal"
                      autoplay={{
                        delay: 0,
                        disableOnInteraction: false,
                        reverseDirection: true,
                      }}
                      loop={true}
                      slidesPerView={7.5}
                      freeMode={true}
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                          speed: 5000,
                        },
                        768: {
                          slidesPerView: 1,
                        },
                        1024: {
                          slidesPerView: 7.5,
                        },
                      }}
                    >
                      {brands.map((brands) => (
                        <SwiperSlide>
                          <img
                            src={`${configs.localApiUrl}${brands.brandImage}`}
                            alt="Slide 1"
                            key={brands.id}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
                <div className="u-wrapper d-block d-md-none">
                  <div className="c-carousel">
                    <Swiper
                      modules={[Autoplay]}
                      direction="horizontal"
                      loop={true}
                      freeMode={true}
                      freeModeMomentum={false}
                      slidesPerView="auto"
                      spaceBetween={gap}
                      speed={4000}
                      watchSlidesProgress={true}
                      watchSlidesVisibility={true}
                      autoplay={{
                        delay: 1,
                        disableOnInteraction: false,
                      }}
                      cssMode={false}
                    >
                      {brands.map((brands) => (
                        <SwiperSlide>
                          <img
                            src={`${configs.localApiUrl}${brands.brandImage}`}
                            alt="Slide 1"
                            key={brands.id}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
                <div className="u-wrapper d-block d-md-none">
                  <div className="c-carousel">
                    <Swiper
                      modules={[Autoplay]}
                      direction="horizontal"
                      loop={true}
                      freeMode={true}
                      freeModeMomentum={false}
                      slidesPerView="auto"
                      spaceBetween={gap}
                      speed={4000}
                      watchSlidesProgress={true}
                      watchSlidesVisibility={true}
                      autoplay={{
                        delay: 1,
                        reverseDirection: true,
                      }}
                      cssMode={false}
                    >
                      {brands.map((brands) => (
                        <SwiperSlide>
                          <img
                            src={`${configs.localApiUrl}${brands.brandImage}`}
                            alt="Slide 1"
                            key={brands.id}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Home;
