import React, { useState, useEffect } from "react";
import CustomLoader from "../../components/CustomeLoader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { status } from "../../_constants/commonLhd";
import { homeAction, pageAction } from "../../_actions";
import { configs } from "../../config";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Helmet } from "react-helmet";

function About() {
  const [loader, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState({});
  const [topBanner, setTopBanner] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 600);
  }, []);

  const pageData = useSelector((state) => state.page);
  const homeData = useSelector((state) => state.home);

  useEffect(() => {
    if (homeData && homeData.top_banner_status === status.SUCCESS) {
      setTopBanner(homeData.top_banner_data);
    }
  }, [homeData]);

  useEffect(() => {
    dispatch(pageAction.getPages());
    dispatch(homeAction.getTopBannerDetail());
  }, []);

  useEffect(() => {
    if (pageData && pageData.page_status === status.SUCCESS) {
      setPage(pageData.page_data);
    }
  }, [pageData.page_data]);

  const handleButtonClick = (aboutButtonLink) => {
    if (aboutButtonLink.startsWith("http")) {
      window.open(aboutButtonLink, "_blank");
    } else {
      navigate(aboutButtonLink);
    }
  };

  return (
    <>
     <Helmet>
        <meta name="description" content={page && page.meta_description} />
        <meta name="keywords" content={page && page.meta_keywords} />
      </Helmet>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="about-col-section first">
            <div className="col-text blue-bg order-last d-none d-md-block">
              <div className="our-courses-box">
                <div className="date-title">
                  <h1>{page.About_title}</h1>
                  {page && page.About_description
                    ? parse(page.About_description)
                    : "Content not available"}
                  {/* {parse(page.aboutDescription)} */}
                  <button
                    className="primary-btn sm"
                    onClick={() => handleButtonClick(page.About_button_link)}
                  >
                    {page.About_text_button}
                    <div className="arrow-icon"></div>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-text blue-bg order-last d-block d-md-none">
              <Swiper
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {topBanner &&
                  topBanner.length > 0 &&
                  topBanner.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div className="our-courses-box">
                        <div className="date-inner-title">
                          <div className="date-title">
                            <h1>{item.title}</h1>
                            {item && item.description ? (
                              parse(item.description)
                            ) : (
                              <p>No description available</p>
                            )}
                            <button
                              className="primary-btn sm"
                              onClick={() => handleButtonClick(item.buttonLink)}
                            >
                              {item.buttonText}
                              <div className="arrow-icon"></div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
            <div className="col-image">
              <img
                src={`${configs.localApiUrl}${page.About_image}`}
                alt="home-col-1"
              />
            </div>
          </div>
          <div className="about-list-section second">
            <div className="container">
              <div
                className="about-item-box"
                onClick={() =>
                  navigate(`/about-history`, {
                    state: page,
                  })
                }
              >
                <div className="about-list-box">
                  {page && page.About_blog_1
                    ? parse(page.About_blog_1)
                    : "Content not available"}
                  {/* {parse(page && page.aboutBlog1)} */}
                </div>
                <div className="about-list-img">
                  <img
                    src={`${configs.localApiUrl}${page.Blog_image_1}`}
                    alt="about-col-2"
                  />
                </div>
              </div>
              <div
                className="about-item-box"
                onClick={() => navigate("/about-history")}
              >
                <div className="about-list-img">
                  <img
                    src={`${configs.localApiUrl}${page.Blog_image_2}`}
                    alt="about-col-2"
                  />
                </div>
                <div className="about-list-box">
                  {page && page.About_blog_2
                    ? parse(page.About_blog_2)
                    : "Content not available"}
                  {/* {parse(page && page.aboutBlog2)} */}
                </div>
              </div>
              <div
                className="about-item-box"
                onClick={() => navigate("/about-history")}
              >
                <div className="about-list-box">
                  {page && page.About_blog_3
                    ? parse(page.About_blog_3)
                    : "Content not available"}
                  {/* {parse(page && page.aboutBlog3)} */}
                </div>
                <div className="about-list-img">
                  <img
                    src={`${configs.localApiUrl}${page.Blog_image_3}`}
                    alt="about-col-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default About;
