import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomLoader from "../../components/CustomeLoader";
import Accordion from "react-bootstrap/Accordion";
import socialicon1 from "../../ulits/images/social-icon1.svg";
import socialicon2 from "../../ulits/images/social-icon2.svg";
import socialicon3 from "../../ulits/images/social-icon3.svg";
import socialicon4 from "../../ulits/images/social-icon4.svg";
import socialicon5 from "../../ulits/images/social-icon5.svg";
import socialicon6 from "../../ulits/images/social-icon6.svg";
import socialicon7 from "../../ulits/images/social-icon7.svg";
import socialicon8 from "../../ulits/images/social-icon8.svg";
import socialicon9 from "../../ulits/images/social-icon9.svg";
import socialicon10 from "../../ulits/images/social-icon10.svg";
import socialicon11 from "../../ulits/images/social-icon11.svg";
import carimg2 from "../../ulits/images/car_img2.png";
import milionicon from "../../ulits/images/milion_icon.svg";
import petrolicon from "../../ulits/images/petrol_icon.svg";
import manualicon from "../../ulits/images/manual_icon.svg";
import bolteg from "../../ulits/images/bolteg.png";
import { useSelector, useDispatch } from "react-redux";
import { configs } from "../../config";
import { homeAction } from "../../_actions";
import { status } from "../../_constants/commonLhd";
import ReactSlider from "react-slider";
import filterbackarrow from "../../ulits/images/filter_back_arrow.svg";
import filtericon from "../../ulits/images/filter_icon.svg";
import { Helmet } from "react-helmet";

function CarListing() {
  const [loader, setLoading] = useState(true);
  const [list, setList] = useState(false);
  const [handDrive, setHandDrive] = useState();
  const [condition, setCondition] = useState();
  const [drive, setDrive] = useState([]);
  const [transmission, setTransmission] = useState([]);
  const [fuelType, setFuelType] = useState([]);
  const [name, setName] = useState([]);
  const [cars, setCars] = useState([]);

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState([0, 500000]);
  const [value, setValue] = useState([2010, new Date().getFullYear()]);
  const [active, setActive] = useState("button1");
  const [valued, setValued] = useState([0, 1000000]);
  const [seo, setSeo] = useState({})

  const currentYear = new Date().getFullYear();


  const ToggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const dispatch = useDispatch();
  const homeData = useSelector((state) => state.home);
  const seoData = useSelector((state) => state.config);

  useEffect(() => {
    if (seoData && seoData.seo_status === status.SUCCESS) {
      setSeo(seoData.seo_data);
    }
  }, [seoData]);

  useEffect(() => {
    dispatch(homeAction.getCarsByTenantId());
  }, []);

  useEffect(() => {
    if (homeData && homeData.car_details_status === status.SUCCESS) {
      setCars(homeData.car_details_data);
    }
  }, [homeData.car_details_data]);

  useEffect(() => {
    let filteredCars = homeData.car_data || [];

    if (handDrive === true) {
      filteredCars = filteredCars.filter((car) => car.leftHendDrive);
    } else if (handDrive === false) {
      filteredCars = filteredCars.filter((car) => car.rightHendDrive);
    } else if (condition === "used") {
      filteredCars = filteredCars.filter((car) => car.condition === "used");
    } else if (condition === "unused") {
      filteredCars = filteredCars.filter((car) => car.condition === "unused");
    }
    if (handDrive === true && condition === "used") {
      filteredCars = filteredCars.filter(
        (car) => car.leftHendDrive && car.condition === "used"
      );
    } else if (handDrive === true && condition === "unused") {
      filteredCars = filteredCars.filter(
        (car) => car.leftHendDrive && car.condition === "unused"
      );
    }
    if (handDrive === false && condition === "used") {
      filteredCars = filteredCars.filter(
        (car) => car.rightHendDrive && car.condition === "used"
      );
    } else if (handDrive === false && condition === "unused") {
      filteredCars = filteredCars.filter(
        (car) => car.rightHendDrive && car.condition === "unused"
      );
    }
    if (drive.length > 0) {
      filteredCars = filteredCars.filter((car) =>
        drive.includes(car.drive.trim())
      );
    }
    if (name.length > 0) {
      filteredCars = filteredCars.filter((car) =>
        name.includes(car.name.trim())
      );
    }

    if (transmission.length > 0) {
      filteredCars = filteredCars.filter((car) =>
        transmission.includes(car.transmission.trim())
      );
    }

    if (fuelType.length > 0) {
      filteredCars = filteredCars.filter((car) =>
        fuelType.includes(car.fuelType.trim())
      );
    }

    filteredCars = filteredCars.filter(
      (car) => car.mileage >= values[0] && car.mileage <= values[1]
    );

    filteredCars = filteredCars.filter(
      (car) =>
        car.menuFacturingYear >= value[0] && car.menuFacturingYear <= value[1]
    );

    filteredCars = filteredCars.filter(
      (car) => car.price >= valued[0] && car.price <= valued[1]
    );

    setCars(filteredCars);
  }, [
    handDrive,
    condition,
    drive,
    name,
    transmission,
    values,
    value,
    valued,
    fuelType,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 600);
  }, []);

  const toggleDrive = (value) => {
    setDrive((prevDrive) =>
      prevDrive.includes(value)
        ? prevDrive.filter((item) => item !== value)
        : [...prevDrive, value]
    );
  };

  const toggleMake = (value) => {
    setName((prevDrive) =>
      prevDrive.includes(value)
        ? prevDrive.filter((item) => item !== value)
        : [...prevDrive, value]
    );
  };

  const toggleTransmission = (value) => {
    setTransmission((prevDrive) =>
      prevDrive.includes(value)
        ? prevDrive.filter((item) => item !== value)
        : [...prevDrive, value]
    );
  };

  const toggleFuelType = (value) => {
    setFuelType((prevDrive) =>
      prevDrive.includes(value)
        ? prevDrive.filter((item) => item !== value)
        : [...prevDrive, value]
    );
  };

  const handleSelection = (e) => {
    setHandDrive(e.target.value);
  };

  const getUniqueSlug = (slug) => {
    let count = 0;
    cars.forEach((car) => {
      if (car.slug === slug) {
        count++;
      }
    });

    if (count > 1) {
      let newSlug = `${slug}-${count - 1}`;
      return newSlug;
    }
    return slug;
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={seo && seo.Car_Listing_Meta_Description}
        />
        <meta name="keywords" content={seo && seo.Car_Listing_Meta_Keywords} />
      </Helmet>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="car-listing-section">
            <div className="container">
              <div className="modern-filter-listing">
                <div
                  className={`car-listing-left ${isOpen == true ? "open" : ""}`}
                >
                  <div className="filter-header">
                    <div
                      className="filter-close"
                      onClick={() => setIsOpen(false)}
                    >
                      <img src={filterbackarrow} alt="Close Menu" />
                    </div>
                  </div>
                  <Accordion defaultActiveKey={["0"]} alwaysOpen>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <div className="car-listing-icon">
                          <img src={socialicon2} alt="location-icon" />
                          CONDITION
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="options">
                          <label>
                            <input
                              type="radio"
                              class="form-check-label"
                              for="flexRadioDefault1"
                              value="used"
                              checked={condition === "used"}
                              onClick={() =>
                                setCondition((prevCondition) =>
                                  prevCondition === "used" ? "" : "used"
                                )
                              }
                            />
                            <span>USED</span>
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="unused"
                              checked={condition === "unused"}
                              onClick={() =>
                                setCondition((prevCondition) =>
                                  prevCondition === "unused" ? "" : "unused"
                                )
                              }
                            />
                            <span>UNUSED</span>
                          </label>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <div className="car-listing-icon">
                          <img src={socialicon3} alt="location-icon" />
                          HAND DRIVE
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="options">
                          <label>
                            <input
                              type="radio"
                              value="true"
                              checked={handDrive == false}
                              onClick={() =>
                                setHandDrive((prev) =>
                                  prev === false ? null : false
                                )
                              }
                            />
                            <span>RIGHT</span>
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="false"
                              checked={handDrive == true}
                              onClick={() =>
                                setHandDrive((prev) =>
                                  prev === true ? null : true
                                )
                              }
                            />
                            <span>LEFT</span>
                          </label>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        <div className="car-listing-icon">
                          <img src={socialicon4} alt="location-icon" />
                          MAKE
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="sort-list">
                          <div className="sort-by">
                            <label>
                              <input
                                type="checkbox"
                                value="Acura"
                                checked={name.includes("Acura")}
                                onChange={() => toggleMake("Acura")}
                              />
                              <span>ACURA</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Audi"
                                checked={name.includes("Audi")}
                                onChange={() => toggleMake("Audi")}
                              />
                              <span>AUDI</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Chevrolet"
                                checked={name.includes("Chevrolet")}
                                onChange={() => toggleMake("Chevrolet")}
                              />
                              <span>CHEVROLET</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Citroen"
                                checked={name.includes("Citroen")}
                                onChange={() => toggleMake("Citroen")}
                              />
                              <span>CITROEN</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Dodge"
                                checked={name.includes("Dodge")}
                                onChange={() => toggleMake("Dodge")}
                              />
                              <span>DODGE</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Ford"
                                checked={name.includes("Ford")}
                                onChange={() => toggleMake("Ford")}
                              />
                              <span>FORD</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Hyundai"
                                checked={name.includes("Hyundai")}
                                onChange={() => toggleMake("Hyundai")}
                              />
                              <span>HYUNDAI</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Jaguar"
                                checked={name.includes("Jaguar")}
                                onChange={() => toggleMake("Jaguar")}
                              />
                              <span>JAGUAR</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="KIA"
                                checked={name.includes("KIA")}
                                onChange={() => toggleMake("KIA")}
                              />
                              <span>KIA</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Maserati"
                                checked={name.includes("Maserati")}
                                onChange={() => toggleMake("Maserati")}
                              />
                              <span>MASERATI</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Mercedes"
                                checked={name.includes("Mercedes")}
                                onChange={() => toggleMake("Mercedes")}
                              />
                              <span>MERCEDEZ</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Nissan"
                                checked={name.includes("Nissan")}
                                onChange={() => toggleMake("Nissan")}
                              />
                              <span>NISSAN</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Porsche"
                                checked={name.includes("Porsche")}
                                onChange={() => toggleMake("Porsche")}
                              />
                              <span>PORSCHE</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Rover"
                                checked={name.includes("Rover")}
                                onChange={() => toggleMake("Rover")}
                              />
                              <span>ROVER</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Skoda"
                                checked={name.includes("Skoda")}
                                onChange={() => toggleMake("Skoda")}
                              />
                              <span>SKODA</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Tesla"
                                checked={name.includes("Tesla")}
                                onChange={() => toggleMake("Tesla")}
                              />
                              <span>TESLA</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Vauxhall/Opel"
                                checked={name.includes("Vauxhall/Opel")}
                                onChange={() => toggleMake("Vauxhall/Opel")}
                              />
                              <span>VAUXHALL/OPEL</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Volvo"
                                checked={name.includes("Volvo")}
                                onChange={() => toggleMake("Volvo")}
                              />
                              <span>VOLVO</span>
                            </label>
                          </div>
                          <div className="sort-by">
                            <label>
                              <input
                                type="checkbox"
                                value="Acuro"
                                checked={name.includes("Acuro")}
                                onChange={() => toggleMake("Acuro")}
                              />
                              <span>ACURO</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="BMW"
                                checked={name.includes("BMW")}
                                onChange={() => toggleMake("BMW")}
                              />
                              <span>BMW</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Chrysler"
                                checked={name.includes("Chrysler")}
                                onChange={() => toggleMake("Chrysler")}
                              />
                              <span>CHRYSLER</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Dacia"
                                checked={name.includes("Dacia")}
                                onChange={() => toggleMake("Dacia")}
                              />
                              <span>DACIA</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="FIAT"
                                checked={name.includes("FIAT")}
                                onChange={() => toggleMake("FIAT")}
                              />
                              <span>FIAT</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Honda"
                                checked={name.includes("Honda")}
                                onChange={() => toggleMake("Honda")}
                              />
                              <span>HONDA</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Infinity"
                                checked={name.includes("Infinity")}
                                onChange={() => toggleMake("Infinity")}
                              />
                              <span>INFINITY</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="JEEP"
                                checked={name.includes("JEEP")}
                                onChange={() => toggleMake("JEEP")}
                              />
                              <span>JEEP</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Rolls Royce"
                                checked={name.includes("Rolls Royce")}
                                onChange={() => toggleMake("Rolls Royce")}
                              />
                              <span>ROLLS ROYCE</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Mazda"
                                checked={name.includes("Mazda")}
                                onChange={() => toggleMake("Mazda")}
                              />
                              <span>MAZDA</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="MINI"
                                checked={name.includes("MINI")}
                                onChange={() => toggleMake("MINI")}
                              />
                              <span>MINI</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Peugeot"
                                checked={name.includes("Peugeot")}
                                onChange={() => toggleMake("Peugeot")}
                              />
                              <span>PEUGOT</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Renault"
                                checked={name.includes("Renault")}
                                onChange={() => toggleMake("Renault")}
                              />
                              <span>RENAULT</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="SEAT"
                                checked={name.includes("SEAT")}
                                onChange={() => toggleMake("SEAT")}
                              />
                              <span>SEAT</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Smart For Two"
                                checked={name.includes("Smart For Two")}
                                onChange={() => toggleMake("Smart For Two")}
                              />
                              <span>SMART FOR TWO</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Toyota"
                                checked={name.includes("Toyota")}
                                onChange={() => toggleMake("Toyota")}
                              />
                              <span>TOYOTA</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="Volkswagen"
                                checked={name.includes("Volkswagen")}
                                onChange={() => toggleMake("Volkswagen")}
                              />
                              <span>VOLKSWAGEN</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="YETI"
                                checked={name.includes("YETI")}
                                onChange={() => toggleMake("YETI")}
                              />
                              <span>YETI</span>
                            </label>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        <div className="car-listing-icon">
                          <img src={socialicon5} alt="location-icon" />
                          MILEAGE
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="slider-labels">
                          <div className="slider-label">MIN</div>
                          <div className="slider-label">MAX</div>
                        </div>
                        <ReactSlider
                          className="range-slider"
                          thumbClassName="thumb"
                          trackClassName="track"
                          defaultValue={values}
                          min={0}
                          max={500000}
                          step={100}
                          value={values}
                          onChange={(val) => setValues(val)}
                          pearling
                        />
                        <div className="slider-values">
                          <span>{values[0]}</span>
                          <span>{values[1]}</span>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="6">
                      <Accordion.Header>
                        <div className="car-listing-icon">
                          <img src={socialicon6} alt="location-icon" />
                          YEAR  ({new Date().getFullYear()})
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="slider-labels">
                          <div className="slider-label">MIN</div>
                          <div className="slider-label">MAX</div>
                        </div>
                        <ReactSlider
                          className="range-slider"
                          thumbClassName="thumb"
                          trackClassName="track"
                          min={2000}
                          max={2025}
                          step={1}
                          value={value}
                          onChange={(val) => setValue(val)}
                          pearling
                        />
                        <div className="slider-values">
                          <span>{value[0]}</span>
                          <span>{value[1]}</span>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item> */}
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>
                        <div className="car-listing-icon">
                          <img src={socialicon6} alt="location-icon" />
                          YEAR
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="slider-labels">
                          <div className="slider-label">MIN</div>
                          <div className="slider-label">MAX</div>
                        </div>
                        <ReactSlider
                          className="range-slider"
                          thumbClassName="thumb"
                          trackClassName="track"
                          min={2010}
                          max={currentYear}
                          step={1}
                          value={value}
                          onChange={(val) => setValue(val)}
                          pearling
                        />
                        <div className="slider-values">
                          <span>{value[0]}</span>
                          <span>{value[1]}</span>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header>
                        <div className="car-listing-icon">
                          <img src={socialicon7} alt="location-icon" />
                          PRICE
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="slider-labels">
                          <div className="slider-label">MIN</div>
                          <div className="slider-label">MAX</div>
                        </div>
                        <ReactSlider
                          className="range-slider"
                          thumbClassName="thumb"
                          trackClassName="track"
                          defaultValue={valued}
                          min={0}
                          max={600000}
                          step={5000}
                          value={valued}
                          onChange={(val) => setValued(val)}
                          pearling
                        />
                        <div className="slider-values">
                          <span>{valued[0]}</span>
                          <span>{valued[1]}</span>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                      <Accordion.Header>
                        <div className="car-listing-icon">
                          <img src={socialicon8} alt="location-icon" />
                          TRANSMISSION
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="sort-list">
                          <div className="sort-by">
                            <label>
                              <input
                                type="checkbox"
                                value="AUTOMATIC"
                                checked={transmission.includes("AUTO")}
                                onChange={() => toggleTransmission("AUTO")}
                              />
                              <span>AUTO</span>
                            </label>
                            {/* <label>
                              <input
                                type="checkbox"
                                value="MANUAL"
                                checked={transmission.includes("MANUAL")}
                                onChange={() => toggleTransmission("MANUAL")}
                              />
                              <span>MANUAL</span>
                            </label> */}
                          </div>
                          <div className="sort-by">
                            {/* <label>
                              <input
                                type="checkbox"
                                value="AUTOMATIC TIPTRONIC"
                                checked={transmission.includes(
                                  "AUTOMATIC TIPTRONIC"
                                )}
                                onChange={() =>
                                  toggleTransmission("AUTOMATIC TIPTRONIC")
                                }
                              />
                              <span>AUTOMATIC TIPTRONIC</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="SEMI AUTOMATIC"
                                checked={transmission.includes(
                                  "SEMI AUTO"
                                )}
                                onChange={() =>
                                  toggleTransmission("SEMI AUTOMATIC")
                                }
                              />
                              <span>SEMI AUTOMATIC</span>
                            </label> */}
                            <label>
                              <input
                                type="checkbox"
                                value="MANUAL"
                                checked={transmission.includes("MANUAL")}
                                onChange={() => toggleTransmission("MANUAL")}
                              />
                              <span>MANUAL</span>
                            </label>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                      <Accordion.Header>
                        <div className="car-listing-icon">
                          <img src={socialicon9} alt="location-icon" />
                          DRIVE
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="sort-list">
                          <div className="sort-by">
                            <label>
                              <input
                                type="checkbox"
                                value="4WD"
                                checked={drive.includes("4WD")}
                                onChange={() => toggleDrive("4WD")}
                              />
                              <span>4WD</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="FWD"
                                checked={drive.includes("FWD")}
                                onChange={() => toggleDrive("FWD")}
                              />
                              <span>FWD</span>
                            </label>
                          </div>
                          <div className="sort-by">
                            <label>
                              <input
                                type="checkbox"
                                value="AWD"
                                checked={drive.includes("AWD")}
                                onChange={() => toggleDrive("AWD")}
                              />
                              <span>AWD</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="RWD"
                                checked={drive.includes("RWD")}
                                onChange={() => toggleDrive("RWD")}
                              />
                              <span>RWD</span>
                            </label>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="10">
                      <Accordion.Header>
                        <div className="car-listing-icon">
                          <img src={petrolicon} alt="location-icon" />
                          FUEL TYPE
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="sort-list">
                          <div className="sort-by">
                            <label>
                              <input
                                type="checkbox"
                                value="PETROL"
                                checked={fuelType.includes("Petrol")}
                                onChange={() => toggleFuelType("Petrol")}
                              />
                              <span>PETROL</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="DIESEL"
                                checked={fuelType.includes("Diesel")}
                                onChange={() => toggleFuelType("Diesel")}
                              />
                              <span>DIESEL</span>
                            </label>
                          </div>
                          <div className="sort-by">
                            <label>
                              <input
                                type="checkbox"
                                value="ELECTRIC"
                                checked={fuelType.includes("Electric")}
                                onChange={() => toggleFuelType("Electric")}
                              />
                              <span>ELECTRIC</span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                value="CNG"
                                checked={fuelType.includes("CNG")}
                                onChange={() => toggleFuelType("CNG")}
                              />
                              <span>CNG</span>
                            </label>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="car-listing-right">
                  <div className="car-listing-right-top">
                    <div className="car-listing-right-top-left">
                      <div className="car-listing-right-top-left-text">
                        <h1>
                          {cars.length} CAR{" "}
                          {cars.length !== 1 ? "MODELS" : "MODEL"} AVAILABLE
                        </h1>
                        <div className="filter-sidebar filter-btn d-block d-md-none">
                          <button
                            className={`filter-menu ${
                              isOpen == true ? "open" : ""
                            }`}
                            onClick={() => setIsOpen(!isOpen)}
                          >
                            <img src={filtericon} alt="location-icon" />
                            FILTER
                            <span>{cars.length}</span>
                          </button>
                        </div>
                        <div className="car-listing-right-top-left-icon d-none d-md-flex">
                          <div
                            className={list ? "icon inactive" : "icon active"}
                            onClick={() => setList(false)}
                          >
                            <img src={socialicon10} alt="location-icon" />
                          </div>
                          <div
                            className={list ? "icon active" : "icon inactive"}
                            onClick={() => setList(true)}
                          >
                            <img src={socialicon11} alt="location-icon" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`${list ? "list-row" : "row"}`}>
                      {cars &&
                        cars.map((cars, index) => {
                          const uniqueSlug = getUniqueSlug(cars.slug, cars);
                          return (
                            <div
                              className={`${
                                list
                                  ? "col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 list-vertical"
                                  : "col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 list-vertical"
                              }`}
                              key={cars._id}
                            >
                              <a>
                                <div className="blog-listing-box">
                                  <div className="blog-listing-img">
                                    <img
                                      src={`${configs.localApiUrl}${cars.image[0]}`}
                                      alt=""
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        navigate(`/car-details/${uniqueSlug}`, {
                                          state: cars,
                                        })
                                      }
                                    />
                                  </div>
                                  <div className="blog-listing-text">
                                    <h1>
                                      {cars && cars.details.toUpperCase()}
                                    </h1>
                                    <ul>
                                      <li>
                                        <Link to="#">
                                          <img
                                            src={milionicon}
                                            alt="arrow-icon"
                                          />
                                          {cars.mileage} MILES
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="#">
                                          <img
                                            src={petrolicon}
                                            alt="arrow-icon"
                                          />
                                          {cars && cars.fuelType.toUpperCase()}
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="#">
                                          <img
                                            src={manualicon}
                                            alt="arrow-icon"
                                          />
                                          {cars &&
                                            cars.transmission.toUpperCase()}
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="#">
                                          <img src={bolteg} alt="arrow-icon" />
                                          {cars.engine}
                                        </Link>
                                      </li>
                                    </ul>
                                    <div className="grop-btn">
                                      <button
                                        className={
                                          active === "button1" ? "active" : ""
                                        }
                                        onClick={() => setActive("button1")}
                                      >
                                        £{cars.price}
                                      </button>
                                      <button
                                        onClick={() =>
                                          navigate(
                                            `/car-details/${uniqueSlug}`,
                                            {
                                              state: cars,
                                            }
                                          )
                                        }
                                      >
                                        DISCOVER
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default CarListing;
