import { configConstant } from "../_constants/config.constant";
import { status } from "../_constants/commonLhd";

let initialState = {};
console.log(initialState);

export function config(state = initialState, action) {
  switch (action.type) {
    case configConstant.GET_CONFIG_REQUEST:
      return {
        ...state,
        config_status: status.IN_PROGRESS,
        config_data: null,
      };
    case configConstant.GET_CONFIG_SUCCESS:
      return {
        ...state,
        config_status: status.SUCCESS,
        config_data: action.data,
      };
    case configConstant.GET_CONFIG_FAILURE:
      return {
        ...state,
        config_status: status.FAILURE,
        config_data: action.data,
      };
    case configConstant.GET_CONFIG_REQUEST:
      return {
        ...state,
        seo_status: status.IN_PROGRESS,
        seo_data: null,
      };
    case configConstant.GET_SEO_SUCCESS:
      return {
        ...state,
        seo_status: status.SUCCESS,
        seo_data: action.data,
      };
    case configConstant.GET_SEO_FAILURE:
      return {
        ...state,
        seo_status: status.FAILURE,
        seo_data: action.data,
      };

    default:
      return state;
  }
}
